import React, { FC, useState } from 'react';
import {
  CloseButton,
  OverlayTrigger,
  Popover,
  PopoverTitle,
} from 'react-bootstrap';
import { SelectFieldType } from '../../../components/Select/select.types';
import MistakesMenu from './MistakesMenu';

const SelectMistakesBulkButton: FC<{
  mistakesList: Array<SelectFieldType>;
  onBulkSendMistakes: (el: any) => void;
}> = ({ mistakesList, onBulkSendMistakes }) => {
  const [clustersPopoverVisible, setClustersPopoverVisible] = useState(false);
  const defaultCrop = {
    id: 'bulkMenuCrop',
    flag: '',
    mistakeValue: [],
    mistakeIsLoading: false,
    isDisabledMistakeButton: true,
  };

  const [crop, setCrop] = useState({ ...defaultCrop });

  const handlePopoverToggle = () => {
    setClustersPopoverVisible((prev) => {
      return !prev;
    });
  };

  const handleChangeState = (value: any, flag: string) => {
    let newState = {};
    switch (flag) {
      case 'flag':
        newState = {
          flag: value,
          mistakeValue: [],
          isDisabledMistakeButton: value !== 'WRONG_TAGGED',
        };
        break;
      case 'mistakeValue':
        newState = {
          mistakeValue: value,
          isDisabledMistakeButton: Object.keys(value).length === 0,
        };
        break;
      default:
        newState = {
          [flag]: value,
        };
    }
    setCrop((prev) => ({ ...prev, ...newState }));
  };

  const onSendMistakes = () => {
    setCrop({ ...defaultCrop });
    handlePopoverToggle();
    onBulkSendMistakes(crop);
  };

  const selectMistakesOverlay = (
    <Popover id="selectMistakesPopover" style={{ maxWidth: 500 }}>
      <PopoverTitle>
        <CloseButton onClick={() => handlePopoverToggle()} />
      </PopoverTitle>
      <Popover.Content>
        <div className="card-сrops">
          <MistakesMenu
            crop={crop}
            isLoading={false}
            onSendMistakes={onSendMistakes}
            handleChangeState={handleChangeState}
            mistakesList={mistakesList}
          />
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      overlay={selectMistakesOverlay}
      show={clustersPopoverVisible}
      onToggle={handlePopoverToggle}
      defaultShow={false}>
      <div className="menu-action">Select mistakes</div>
    </OverlayTrigger>
  );
};

export default SelectMistakesBulkButton;
