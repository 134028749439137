import React, { FC } from 'react';
import { Form, Row } from 'react-bootstrap';
import Select from '../../../components/Select/Select';
import SpinnerButton from '../../../components/Buttons/SpinnerButton';
import { SelectFieldType } from '../../../components/Select/select.types';

export interface MistakesMenuProps {
  crop: any;
  isLoading: boolean;
  onSendMistakes: (el: any) => void;
  handleChangeState: (value: any, flag: string, id: string) => void;
  mistakesList: Array<SelectFieldType>;
}

const MistakesMenu: FC<MistakesMenuProps> = ({
  isLoading,
  crop,
  onSendMistakes,
  handleChangeState,
  mistakesList,
}) => {
  const mistakesRadioGroup = ['WRONG_BOTH', 'WRONG_TAGGED', 'WRONG_PREDICTION'];

  return (
    <div className="pl-4 mt-n3" data-testid={`mistakes-menu__${crop.id}`}>
      <Row>
        <Form className="mr-3 mt-3 pl-3">
          {mistakesRadioGroup.map((itemRadio) => (
            <Form.Check.Label className="d-block" key={itemRadio}>
              <Form.Check.Input
                name="radioGroup"
                id={itemRadio}
                checked={crop.flag === itemRadio}
                onChange={(e: { target: { value: any } }) =>
                  handleChangeState(e.target.value, 'flag', crop.id)
                }
                value={itemRadio}
                type="radio"
              />
              <span className="text-large">{itemRadio}</span>
            </Form.Check.Label>
          ))}
        </Form>
        <div className="mr-3 mt-3">
          <Select
            value={crop.mistakeValue}
            onChange={(e: any) => handleChangeState(e, 'mistakeValue', crop.id)}
            isDisabled={
              crop.mistakeIsLoading ||
              crop.flag === 'WRONG_TAGGED' ||
              !crop.flag
            }
            options={mistakesList}
            placeholder="Select mistake(s)"
            closeMenuOnSelect={false}
            isMulti
          />
        </div>
        <div className="mr-3 mt-3">
          <SpinnerButton
            style={{ height: '50px' }}
            isLoading={isLoading}
            disabled={crop.isDisabledMistakeButton}
            onClick={onSendMistakes}
            value="Send"
          />
        </div>
      </Row>
    </div>
  );
};

export default MistakesMenu;
