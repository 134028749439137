import React, { FC } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import CustomSpinner from '../../../components/CustomSpinner/CustomSpinner';

const InfoCardMoreInfo: FC<{
  variant: 'day' | 'type';
  data?: Array<{ label: string; value: string }>;
  isLoading?: boolean;
  isError?: boolean;
  onClick?: () => void;
}> = ({ variant, data, isLoading = false, isError = false, onClick }) => {
  const styles: Record<string, React.CSSProperties> = {
    headerContainer: {
      position: 'relative',
      cursor: 'pointer',
    },
    wrapper: {
      position: 'absolute',
      left: 15,
      right: 15,
      background: 'inherit',
      zIndex: 101,
      marginTop: 2,
      boxShadow: 'rgba(0, 0, 0, 1) 0 20px 20px -20px',
    },
    wrapper2: {
      position: 'absolute',
      left: 15,
      right: 15,
      background: 'inherit',
      zIndex: 100,
      marginTop: 2,
      boxShadow: 'rgba(0, 0, 0, 1) 0 20px 20px -20px',
    },
    subContainer: {
      maxHeight: '50vh',
      overflow: 'auto',
    },
    topPlus: {
      position: 'absolute',
      right: 16,
      top: 16,
    },
    topMinus: {
      position: 'absolute',
      right: 16,
      top: -37,
      background: '#fff',
      pointerEvents: 'none',
    },
  };
  const isDay = variant === 'day';

  const title = isDay ? 'More info (per day)' : 'More info (mistake types)';
  return (
    <Accordion data-testid={`info-card__more-info_${variant}`}>
      <Accordion.Toggle
        eventKey="1"
        as="div"
        style={styles.headerContainer}
        onClick={onClick}
        data-testid="info-card__more-info-header">
        <Card className="m-0">
          <Card.Body>
            {title}
            <FontAwesomeIcon icon={faPlus} style={styles.topPlus} />
          </Card.Body>
        </Card>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1" style={styles.wrapper2}>
        <Card className="m-0 p-0">
          <Card.Body className="m-0 p-0">
            <FontAwesomeIcon icon={faMinus} style={styles.topMinus} />
            {isLoading && <CustomSpinner />}
            {isError && (
              <span
                className="error-text text-nowrap"
                data-testid="info-card__more-info_error">
                Sorry, something went wrong
              </span>
            )}
            {!isLoading && !isError && (
              <div
                style={styles.subContainer}
                data-testid={`info-card__more-info_data-${variant}`}>
                <ul className="py-2 px-3 mt-0 mb-0 mr-0 ml-3">
                  {data &&
                    data.map((el: any) => (
                      <li key={isDay ? el.date : el.label}>
                        {isDay ? (
                          <>
                            {el.date}: {el.images_count} images,{' '}
                            {el.crops_count} crops
                          </>
                        ) : (
                          <>
                            {el.label}: {el.value}
                          </>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </Card.Body>
        </Card>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default InfoCardMoreInfo;
