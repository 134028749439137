// TODO: Add key events
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  CheckIcon,
  CheckSelectedIcon,
  GoBackIcon,
  NextIcon,
  PrevIcon,
  ShelfIcon,
} from 'components/Icons';
import React, { FC, UIEvent, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Point } from 'contexts/StationStateContext.types';
import { useStationStateContext } from '../../../contexts/StationStateContext';

const CropView: FC<{
  crops: Point[];
}> = ({ crops }) => {
  const {
    cropViewId,
    selectedCrops,
    setCropViewId,
    onSelectCrop,
    setShelfViewCropId,
    shelfViewCropId,
  } = useStationStateContext();
  const [currentCrop, setCurrentCrop] = useState<Point | undefined>();
  useEffect(() => {
    setCurrentCrop(crops?.find(({ id }) => id === cropViewId));
  }, [crops, cropViewId]);

  const onCloseCropView = () => {
    setCropViewId(undefined);
  };
  const onShowShelfView = (cropId: string) => {
    setShelfViewCropId(cropId || null);
  };
  const navigateCrop = (e: UIEvent | KeyboardEvent, next: boolean) => {
    e.stopPropagation();
    if (!currentCrop) return;
    const index =
      (crops.indexOf(currentCrop) + (next ? 1 : -1) + crops.length) %
      crops.length;
    setCurrentCrop(crops[index]);
  };

  const selected = currentCrop && selectedCrops[currentCrop.id];

  useHotkeys(
    'enter, space',
    () => currentCrop && onSelectCrop(currentCrop.id),
    { enabled: !!cropViewId },
  );
  useHotkeys(
    'escape',
    (e) => {
      e.stopPropagation();
      onCloseCropView();
    },
    { enabled: !!cropViewId && !shelfViewCropId },
  );
  useHotkeys('left', (e) => navigateCrop(e, false), {
    enabled: !!cropViewId,
  });
  useHotkeys('right', (e) => navigateCrop(e, true), {
    enabled: !!cropViewId,
  });
  useHotkeys('s', () => currentCrop && onShowShelfView(currentCrop.id), {
    enabled: !!cropViewId,
  });

  if (!cropViewId) return null;
  return (
    // TODO: Add hover state to buttons
    <div className="crop-view">
      <img
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
        src={currentCrop?.crop_path}
        alt="crop-preview"
      />
      <div
        className="crop-view-button button-prev"
        title="Previous crop"
        onClick={(e) => navigateCrop(e, false)}>
        <PrevIcon />
      </div>
      <div
        className="crop-view-button button-next"
        title="Next crop"
        onClick={(e) => navigateCrop(e, true)}>
        <NextIcon />
      </div>
      <div
        className="crop-view-button button-back"
        title="Close"
        onClick={(e) => {
          e.stopPropagation();
          onCloseCropView();
        }}>
        <GoBackIcon />
      </div>
      <div
        className="crop-view-button button-select"
        title={selected ? 'Selected' : 'Not selected'}
        onClick={(e) => {
          e.stopPropagation();
          if (currentCrop) onSelectCrop(currentCrop.id);
        }}>
        {selected ? <CheckSelectedIcon /> : <CheckIcon />}
      </div>
      <div
        className="crop-view-button button-shelf-view"
        title="View shelf"
        onClick={(e) => {
          e.stopPropagation();
          if (currentCrop) onShowShelfView(currentCrop.id);
        }}>
        <ShelfIcon />
      </div>
    </div>
  );
};
CropView.displayName = 'CropView';

export default CropView;
