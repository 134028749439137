import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  getNextCrop,
  getCropJobsLabels,
  updateCropStatus,
  getSimilarCrops
} from 'api/crops';
import { HttpStatus } from 'enums';
import { PAGE_SIZE } from 'components/Paginators/ListPaginator';
import { getPredefinedClass } from 'api/brandbank';
import { getCrop, updateLabelName } from 'api/featurestore';
import { getJobTypes } from 'api/jobTypes';
import {START_PAGE} from "../helpers/getPageNumber";

const BatchLabelingContext = React.createContext();

export const useBatchLabelingContext = () =>
  React.useContext(BatchLabelingContext);

class BatchLabelingProcess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      warningMessage: undefined,
      warning: false,
      errorMessage: undefined,
      cropJobs: [],
      prereviewCrop: undefined,
      predefinedSet: [],
      count: null,
      previouse: null,
      next: null,
      predefinedSetUrls: [],
      jobType: undefined,
      selectedJobType: undefined,
      jobTypeList: [],
      isLoading: false,
      labelValue: '',
      newLabelsList: [],
      showNotification: false,
      similarSKU: [],
      action: undefined,
    };
    this.requestsProcessed = 0;
  }

  setAction = (action) => {
    this.setState((prev) => ({ ...prev, action }));
  };

  fetchJobTypes = (props) => {
    this.setState((prev) => ({
      ...prev,
      jobTypeList: [],
      jobType: undefined,
      labelValue: ''
    }));
    getJobTypes(props).then(async (response) => {
      const data = await response.json();
      this.setState((prev) => ({ ...prev, jobTypeList: data.results }));
    });
  };

  serviceCropToFinishedState = ({
    cropJob,
    selectedLabel,
    requestsProcessed,
    userType,
    jobState,
    num
  }) => {
    if (this.requestsProcessed === 0) {
      this.requestsProcessed = requestsProcessed;
    }
    const { id, label_id } = cropJob;
    const { predefinedSet, jobType } = this.state;

    const predefined = predefinedSet
      .filter((item) => item.name === selectedLabel)
      .shift();

    if (!predefined) {
      this.setError(
        `BrandBank predefined class not defined properly for this job. Can you please verify it?`
      );
      this.setIsLoading(false);
      return;
    }

    updateCropStatus(userType, id, {
      state: jobState,
      new_label: selectedLabel
    });
    updateLabelName(label_id, {
      new_label: selectedLabel,
      new_uuid: predefined.id
    }).then(() => {
      this.removeCropJob(cropJob);
      this.requestsProcessed -= 1;
      if (this.requestsProcessed === 0) {
        const { labelValue } = this.state;
        this.fetchBatchCrops(userType, jobType.id, num, labelValue);
      }
    });
  };

  setCountRequestsToProcess = (count) => {
    this.requestsProcessed = count;
  };

  decreaseCountRequestsToProcess = () => {
    this.requestsProcessed -= 1;
  };

  setError = (errorMessage = undefined) =>
    this.setState((prev) => ({
      ...prev,
      error: true,
      errorMessage,
      isLoading: false
    }));

  setWarning = (warningMessage = undefined) =>
    this.setState((prev) => ({ ...prev, warningMessage, isLoading: false }));

  clearError = () =>
    this.setState((prev) => ({
      ...prev,
      error: false,
      errorMessage: undefined,
      isLoading: false
    }));

  addNewCropJob = (newCropJob, source) => {
    const { cropJobs } = this.state;
    newCropJob.source = source;
    newCropJob.selected = false;
    newCropJob.newLabel = newCropJob.source.object.description.name;
    if (newCropJob.state === 'RETURNED') {
      cropJobs.unshift(newCropJob);
    } else {
      cropJobs.push(newCropJob);
    }

    this.decreaseCountRequestsToProcess();

    const isLoading = !!this.requestsProcessed;

    this.setState((prevState) => ({ ...prevState, cropJobs, isLoading }));
  };

  clearNotification = () =>
    this.setState((prev) => ({
      ...prev,
      showNotification: false,
      error: false,
      errorMessage: undefined,
      isLoading: false
    }));

  getSimilarPhotos = async (cropJob) => {
    try {
      const response = await getCrop(cropJob.label_id, {
        photo_id: cropJob.photo_id,
        centroid_scale: 1,
        photo_as_url: true
      });

      const source = await response.json();

      this.setState((prevState) => ({
        similarSKU: [...prevState.similarSKU, source]
      }));
    } catch (error) {
      console.log(error);
    }
  };

  getSelectedLabel = (selectedLabel) => {
    const { jobType, action } = this.state;
    this.setState((prev) => ({ ...prev, similarSKU: [] }));
    if (!jobType || (action !== 'reviewing' && action !== 'labeling')) return;
    getSimilarCrops({ new_label: selectedLabel, jobType: jobType.id }).then(
      async (response) => {
        const data = await response.json();
        // TODO: fixme
        // eslint-disable-next-line array-callback-return
        data.results.map((item) => {
          this.getSimilarPhotos(item);
        });
      }
    );
  };

  fetchCropSource = async (cropJob) => {
    try {
      const response = await getCrop(cropJob.label_id, {
        photo_id: cropJob.photo_id,
        centroid_scale: 1,
        photo_as_url: true
      });

      const source = await response.json();
      this.addNewCropJob(cropJob, source);
    } catch (error) {
      this.setState((prev) => ({ ...prev, showNotification: true }));
      setTimeout(() => this.clearNotification(), 1000);
    }
  };

  fetchBatchCropsSource = (newCropJobs) => {
    const { cropJobs } = this.state;
    const difference = newCropJobs.filter(
      (cropJob) => !cropJobs.find((oldCropJob) => oldCropJob.id === cropJob.id)
    );
    if (difference.length > 0) {
      this.setCountRequestsToProcess(difference.length);
      difference.map(async (cropJob) => await this.fetchCropSource(cropJob));
    } else {
      this.setIsLoading(false);
    }
  };

  handleLoadPredefinedClass = async (
      selectedLabel,
      page = 1,
      keyName = 'name_like'
  ) => {
    const { selectedJobType } = this.state;

    if (!selectedJobType.predefined_classes_id) {
      throw new Error('Predefined class id is empty');
    }

    this.setState((prev) => ({ ...prev, isLoadingSKU: true }));

    const response = await getPredefinedClass({
      predefined_classes: selectedJobType.predefined_classes_id,
      [keyName]: selectedLabel,
      page,
      page_size: PAGE_SIZE
    });

    if (response.status === HttpStatus.BAD_REQUEST) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Bad Request');
    }

    return await response.json();
  }

  loadPredefinedClass = async (
    selectedLabel,
    page = 1,
    keyName = 'name_like'
  ) => {
    try {
      const data = await this.handleLoadPredefinedClass(
          selectedLabel,
          page,
          keyName
      );

      const predefinedSet = data.results;
      const predefinedSetUrls = predefinedSet.reduce((acc, obj) => {
        const { name, photos } = obj;
        [acc[name]] = photos;
        return acc;
      }, {});

      this.setState((prev) => ({
        ...prev,
        count: data.count,
        next: data.next,
        previous: data.previous,
        page,
        predefinedSet,
        predefinedSetUrls,
        warning: ''
      }));
    } catch (err) {
      console.error(err);
      this.setState((prev) => ({
        ...prev,
        warning: 'Something went wrong. Try again later.'
      }));
    } finally {
      this.setState((prev) => ({ ...prev, isLoadingSKU: false }));
    }
  };

  loadBestMatch = async (
      selectedLabel,
      page = 1,
      keyName = 'name_like'
  ) => {

    try {
      const data = await this.handleLoadPredefinedClass(
          selectedLabel,
          page,
          keyName
      );

      return  data.results;

    } catch (err) {
      console.error(err);
      this.setState((prev) => ({
        ...prev,
        warning: 'Something went wrong. Try again later.'
      }));
    } finally {
      this.setState((prev) => ({ ...prev, isLoadingSKU: false }));
    }
  };

  infiniteLoadPredefinedClass = async (
      selectedLabel,
      page = START_PAGE,
      keyName = 'name_like'
  ) => {
    const {predefinedSet} = this.state;


    try {
      const data = await this.handleLoadPredefinedClass(
          selectedLabel,
          page,
          keyName
      );

      const loadedSet = (predefinedSet || []).concat(data.results);
      const predefinedSetUrls = loadedSet.reduce((acc, {name, photos}) => {
        acc[name] = photos[0];
        return acc;
      }, {});

      this.setState({
        count: data.count,
        next: data.next,
        previous: data.previous,
        page,
        predefinedSet: loadedSet,
        predefinedSetUrls,
        warning: '',
      });
    } catch (err) {
      console.error(err);
      this.setState({warning: 'Something went wrong. Try again later.'});
    } finally {
      this.setState({isLoadingSKU: false});
    }
  }

  fetchBatchCrops = async (
    userType,
    jobTypeId,
    limit,
    labelValue = undefined
  ) => {
    this.setState((prev) => ({
      ...prev,
      error: false,
      errorMessage: undefined,
      prereviewCrop: undefined,
      isLoading: true
    }));

    let props = { limit };
    props = jobTypeId ? { ...props, job_type: jobTypeId } : props;
    props = labelValue ? { ...props, new_label: labelValue } : props;
    const response = await getNextCrop(userType, props);

    if (response.status === HttpStatus.SUCCESS) {
      let data = await response.json();
      data = data instanceof Array ? data : [data];
      this.fetchBatchCropsSource(data);
    } else {
      this.clearCropJobs();
      let data = await response.json();
      data = data instanceof Array ? data.shift() : JSON.stringify(data);
      this.setError(data);
    }
  };

  clearCropJobs = () => {
    this.setState((prev) => ({
      ...prev,
      error: false,
      errorMessage: undefined,
      cropJobs: [],
      prereviewCrop: undefined
    }));
  };

  fetchPrereviewCrop = async ({ label_id, photo_id }) => {
    const response = await getCrop(label_id, {
      photo_id,
      centroid_scale: 2.0,
      photo_as_url: true
    });
    const source = await response.json();
    const prereviewCrop = {};
    prereviewCrop.source = source;
    prereviewCrop.photoId = photo_id;
    this.setState((prevState) => ({ ...prevState, prereviewCrop }));
  };

  toogleCropJob = (cropJob) => {
    const { cropJobs } = this.state;
    const index = cropJobs.indexOf(cropJob);
    cropJobs[index].selected = !cropJobs[index].selected;
    this.setState((prevState) => ({ ...prevState, cropJobs }));
  };

  showPrereviewCrop = (cropJob) => {
    this.fetchPrereviewCrop(cropJob);
  };

  removeCropJob = (cropJob) => {
    const { cropJobs } = this.state;
    const index = cropJobs.indexOf(cropJob);

    if (index > -1) {
      cropJobs.splice(index, 1);
    }

    this.setState((prevState) => ({ ...prevState, cropJobs }));
  };

  selectCropJob = (cropJob, selected) => {
    const { cropJobs } = this.state;
    const index = cropJobs.indexOf(cropJob);
    cropJobs[index].selected = selected;

    this.setState((prevState) => ({ ...prevState, cropJobs }));
  };

  fetchCropJobNewLabels = async (
    jobTypeId,
    batchType,
    clearSettings = false
  ) => {
    if (clearSettings) {
      this.setState((prev) => ({ ...prev, labelValue: '', newLabelsList: [] }));
    }
    const response = await getCropJobsLabels({
      job_type: jobTypeId,
      batch_type: batchType
    });

    if (response.status === HttpStatus.SUCCESS) {
      let newLabelsList = await response.json();
      newLabelsList = newLabelsList.map((item) => item.new_label);
      this.setState((prev) => ({ ...prev, newLabelsList }));
    }
  };

  clearSKU = () => {
    this.setState((prev) => ({
      ...prev,
      predefinedSet: [],
      predefinedSetUrls: []
    }));
  };

  clearSelectedJobType = () => {
    this.setState((prev) => ({ ...prev, selectedJobType: undefined }));
  };

  handleJobTypeChange = (selectedJobType) => {
    this.setState((prev) => ({ ...prev, selectedJobType }));
  };

  onClickJobType = (jobType, userType, limit) => {
    this.setState((prev) => ({
      ...prev,
      selectedJobType: jobType,
      jobType,
      predefinedSet: []
    }));
    this.clearSKU();
    const { labelValue } = this.state;
    this.clearCropJobs();
    this.fetchBatchCrops(userType, jobType.id, limit, labelValue);
  };

  onClickLabelName = async (labelValue, userType, limit) => {
    const { jobType } = this.state;
    this.setState((prev) => ({ ...prev, labelValue }));
    this.clearCropJobs();
    this.fetchBatchCrops(userType, jobType.id, limit, labelValue);
  };

  setIsLoading = (isLoading) => {
    this.setState((prev) => ({ ...prev, isLoading }));
  };

  clearPredefinedClass = () => {
    this.setState((prev) => ({
      ...prev,
      predefinedSet: [],
      predefinedSetUrls: []
    }));
  };

  render() {
    const { children } = this.props;
    return (
      <BatchLabelingContext.Provider
        value={{
          ...this.state,
          fetchBatchCrops: this.fetchBatchCrops,
          getSelectedLabel: this.getSelectedLabel,
          fetchCropJobNewLabels: this.fetchCropJobNewLabels,
          clearSKU: this.clearSKU,
          clearSelectedJobType: this.clearSelectedJobType,
          handleJobTypeChange: this.handleJobTypeChange,
          setIsLoading: this.setIsLoading,
          setWarning: this.setWarning,
          setError: this.setError,
          setAction: this.setAction,
          clearError: this.clearError,
          showPrereviewCrop: this.showPrereviewCrop,
          toogleCropJob: this.toogleCropJob,
          selectCropJob: this.selectCropJob,
          removeCropJob: this.removeCropJob,
          clearCropJobs: this.clearCropJobs,
          onClickJobType: this.onClickJobType,
          fetchJobTypes: this.fetchJobTypes,
          onClickLabelName: this.onClickLabelName,
          loadPredefinedClass: this.loadPredefinedClass,
          clearPredefinedClass: this.clearPredefinedClass,
          serviceCropToFinishedState: this.serviceCropToFinishedState,
          setAutoLoad: this.setAutoLoad,
          fetchPrereviewCrop: this.fetchPrereviewCrop,
          infiniteLoadPredefinedClass: this.infiniteLoadPredefinedClass,
          loadBestMatch: this.loadBestMatch,
        }}
      >
        {children}
      </BatchLabelingContext.Provider>
    );
  }
}

BatchLabelingProcess.propTypes = {
  children: PropTypes.node
};

BatchLabelingProcess.defaultProps = {
  children: null
};

export default BatchLabelingProcess;
