import React from 'react';

export const validateUUID = (
  uuid: string,
  setValidationError: React.Dispatch<React.SetStateAction<string>>,
): void => {
  const uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

  setValidationError(
    !uuid || uuidRegex.test(uuid) ? '' : 'Invalid UUID format',
  );
};
