import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Row } from 'react-bootstrap';
import SpinnerButton from '../../components/Buttons/SpinnerButton';
import ButtonGroupSorting from '../../components/ButtonCroup/buttonGroupSorting';
import SelectAuditReport from '../../components/Select/SelectAuditReport';

function ProductionReportFilters({ filterCallback, isLoading }) {
  const sorting = {
    label: 'Sorting on Created',
    valueAsc: '-created_at',
    valueDesc: 'created_at',
  };

  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [selectedSorting, setSelectedSorting] = useState(sorting.valueAsc);

  const onClick = () => {
    filterCallback(
      selectedClient,
      selectedModel,
      selectedType,
      selectedContainer,
      selectedSorting,
    );
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h5">Additional Filters:</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row className="mt-n3 pl-3 pb-2">
          <SelectAuditReport
            selectType="clients"
            selected={selectedClient}
            setSelected={setSelectedClient}
            isDisabled={isLoading}
            className="mr-3 mt-3"
          />
          <SelectAuditReport
            selectType="models"
            selected={selectedModel}
            setSelected={setSelectedModel}
            isDisabled={isLoading}
            className="mr-3 mt-3"
          />
          <SelectAuditReport
            selectType="types"
            selected={selectedType}
            setSelected={setSelectedType}
            isDisabled={isLoading}
            className="mr-3 mt-3"
          />
          <SelectAuditReport
            selectType="containers"
            selected={selectedContainer}
            setSelected={setSelectedContainer}
            isDisabled={isLoading}
            className="mr-3 mt-3"
          />
          <div className="mr-3 mt-3">
            <ButtonGroupSorting
              sortingEl={sorting}
              onChangeSorting={setSelectedSorting}
              selectedSorting={selectedSorting}
              isLoading={isLoading}
            />
          </div>
          <div className="mr-3 mt-3">
            <SpinnerButton
              isLoading={isLoading}
              disabled={false}
              onClick={onClick}
              value="Search"
              style={{ minHeight: 50 }}
            />
          </div>
        </Row>
      </Card.Body>
    </Card>
  );
}

ProductionReportFilters.propTypes = {
  filterCallback: PropTypes.func,
  isLoading: PropTypes.bool,
};

ProductionReportFilters.defaultProps = {
  isLoading: false,
};

export default ProductionReportFilters;
