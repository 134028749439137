import React, { FC, useEffect, useState } from 'react';
import { useBatchLabelingContext } from '../../../contexts/BatchLabelingProcess';
import { useStationStateContext } from '../../../contexts/StationStateContext';
import { getPredefinedClass } from '../../../api/brandbank';
import { PAGE_SIZE } from '../../../components/Paginators/ListPaginator';
import CustomSpinner from '../../../components/CustomSpinner/CustomSpinner';
import { getGPTProposals } from '../../../api/clusters';
import { LabelTO } from '../../../api/api.types';
import CardsList from '../../../components/CardList/CardsList';
import getPageNumber, { START_PAGE } from '../../../helpers/getPageNumber';

interface FastLineGPTSuggestionProps {
  isShow: boolean;
  setSelectedLabel: React.Dispatch<LabelTO | null>;
  selectedLabel: LabelTO | null;
}
const FastLaneGPTSuggestion: FC<FastLineGPTSuggestionProps> = ({
  isShow,
  selectedLabel,
  setSelectedLabel,
}) => {
  const { predefinedSet, selectedJobType } = useBatchLabelingContext();
  const { clusterData } = useStationStateContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (predefinedSet.length && clusterData?.brandbank_uuid) {
      predefinedSet.forEach((crop: any) => {
        if (crop.uuid === clusterData.brandbank_uuid) {
          setSelectedLabel(crop);
        }
      });
    }
    // TODO: Fix me if you see me
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predefinedSet, clusterData?.brandbank_uuid]);

  const [state, setState] = React.useState({
    count: 0,
    page: START_PAGE,
    next: null,
    results: [],
  });

  const loadLabelsName = async (dataResults: any) => {
    const labels: any[] = [];
    await Promise.all(
      dataResults.map(async (bb_uuid: any) => {
        await getPredefinedClass({
          predefined_classes: selectedJobType.predefined_classes_id,
          entity_id: bb_uuid.brandbank_uuid,
          page: 1,
          page_size: PAGE_SIZE,
        })
          .then(async (response: any) => {
            if (!response.ok) {
              throw new Error(`HTTP error: ${response.status}`);
            }
            return response.json();
          })
          .then((source: any) => {
            labels.push({
              ...bb_uuid,
              id: bb_uuid.brandbank_uuid,
              name: source.results[0]?.name,
              photos: source.results[0]?.photos,
            });
          })

          .catch((err: any) => {
            console.error(`Fetch problem: ${err.message}`);
          });
      }),
    );
    return labels;
  };

  const loadGPTProposals = async () => {
    const pageNumber = getPageNumber(state.next);
    getGPTProposals(clusterData?.id, { page: pageNumber, page_size: PAGE_SIZE })
      .then((response: any) => response.json())
      .then(async (data: any) => {
        const newResults = await loadLabelsName(data.results);
        setState((prev) => ({
          ...prev,
          ...data,
          results: [...state.results, ...newResults],
          page: pageNumber,
        }));
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    if (isShow) loadGPTProposals();
    // TODO: Fix me if you see me
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow]);

  if (!isShow) return null;
  return isLoading ? (
    <CustomSpinner />
  ) : (
    <div
      className="d-flex flex-grow-1 mr-n3 pr-3 overflow-auto container_scrolled"
      id="scrollableDiv"
      data-testid="card-list-suggestion">
      {state.results.length > 0 ? (
        <CardsList
          dataLength={state.results.length}
          next={loadGPTProposals}
          hasMore={!!state.next}
          scrollableTarget="scrollableDiv"
          items={state.results}
          onCardClick={(item) => setSelectedLabel(item)}
          activeCardId={selectedLabel?.id}
        />
      ) : (
        <>Nothing found</>
      )}
    </div>
  );
};

export default FastLaneGPTSuggestion;
