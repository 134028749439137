import moment, { Moment } from 'moment';
import { getData } from './base';

export const API_DATE_FORMAT = 'DD-MM-YYYY';

export type Station =
  | 'pre-filtering'
  | 'cleaning'
  | 'cleaning_qc'
  | 'identification'
  | 'identification_qc'
  | 'fast-lane';

export const getIndividualPerformanceUnits = (
  jobType: number,
  station: Station,
  dateFrom: Date | Moment,
  dateTo: Date | Moment,
): Promise<Response> => {
  const params = {
    job_type_id: jobType,
    substate: station,
    from_date: moment(dateFrom).format(API_DATE_FORMAT),
    to_date: moment(dateTo).format(API_DATE_FORMAT),
  };
  const pathname = 'clusters/performance/unit/';
  return getData(pathname, params);
};

export const getThersholds = (
  jobType: number,
  station: Station,
): Promise<Response> => {
  const params = {
    job_type_id: jobType,
    substate: station,
  };
  const pathname = 'clusters/performance/thresholds/';
  return getData(pathname, params);
};
