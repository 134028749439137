import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { faBottleWater } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SpinnerButton from '../../../components/Buttons/SpinnerButton';
import { PAGE_SIZE } from '../../../components/Paginators/ListPaginator';
import { HttpStatus } from '../../../enums';
import { getAuditReportBBS } from '../../../api/auditReport';

interface ProductionReportCheckTopPanelProps {
  state: any;
  skuProgress:
    | {
        crops_checked_count: number | string;
        crop_auto_checked_count: number | string;
        crops_count: number | string;
      }
    | null
    | undefined;
  isViewer: boolean | null | undefined;
  isLoadingAllMistakeButton: boolean | null | undefined;
  isDisabledAllMistakeButton: boolean | null | undefined;
  onSendAllMistakes: () => void;
}

const ProductionReportCheckTopPanel: FC<ProductionReportCheckTopPanelProps> = ({
  state,
  skuProgress,
  isViewer,
  isLoadingAllMistakeButton,
  isDisabledAllMistakeButton,
  onSendAllMistakes,
}) => {
  const [parentInfo, setParentInfo] = useState<any>(null);
  const [backLink, setBackLink] = useState<any>({});
  const styles: Record<string, React.CSSProperties> = {
    mainContainer: {
      background: '#fff',
      position: 'sticky',
      top: '-20px',
      borderBottom: '1px solid rgba(0,0,0,.125)',
      padding: '16px 24px',
      zIndex: 1035,
      margin: '-20px -24px 20px',
    },
    imageContainer: {
      border: '1px solid rgba(0,0,0,.125)',
      maxHeight: '106px',
      maxWidth: '106px',
      boxSizing: 'border-box',
      position: 'relative',
      borderRadius: 4,
    },
    imageStyles: { maxHeight: '82px', maxWidth: '82px' },
    label: { flex: 1 },
    leftPart: { flex: 1 },
  };

  useEffect(() => {
    if (state && state.label) {
      setParentInfo({
        label: state.label,
        bb_UUID: state.brandbank_uuid,
      });
      getAuditReportBBS({
        name_like: state.label,
        page_size: PAGE_SIZE,
      }).then(async (response: { status: any; json: () => any }) => {
        const status = await response.status;
        if (status === HttpStatus.SUCCESS) {
          const data = await response.json();
          setParentInfo({
            label: state.label,
            bb_UUID: state.brandbank_uuid,
            url: data.results?.[0]?.photos?.[0].url,
          });
        }
      });
      setBackLink({ pathname: state.pathnameBack, state: state.stateBack });
    }
  }, [state]);
  return (
    <div
      className="d-flex justify-content-between"
      style={styles.mainContainer}
      data-testid="top-panel__wrapper">
      <div className="d-flex justify-content-start" style={styles.leftPart}>
        <Link to={backLink}>
          <Button variant="light">Back to SKU view</Button>
        </Link>
        <div
          style={styles.imageContainer}
          className="d-flex align-items-center justify-content-center h-100 w-100 p-2 mx-3">
          {parentInfo?.url ? (
            <img src={parentInfo?.url} alt="" style={styles.imageStyles} />
          ) : (
            <FontAwesomeIcon
              style={{ color: 'lightgray', height: '75%' }}
              icon={faBottleWater}
            />
          )}
        </div>
        <div className="mr-3" style={styles.label}>
          {parentInfo?.label}
          <div className="text-nowrap">( {parentInfo?.bb_UUID} )</div>
          <b>
            Progress
            <span className="mx-2">{skuProgress?.crops_checked_count}</span>
            (auto
            <span className="mx-2">
              {skuProgress?.crop_auto_checked_count})
            </span>
            / {skuProgress?.crops_count}
          </b>
        </div>
      </div>
      {!isViewer && (
        <SpinnerButton
          className="align-self-start btn btn-fill"
          isLoading={isLoadingAllMistakeButton}
          disabled={isDisabledAllMistakeButton}
          onClick={() => onSendAllMistakes()}
          value="Send All"
        />
      )}
    </div>
  );
};

export default ProductionReportCheckTopPanel;
