import React, { FC, useCallback, SetStateAction, Dispatch } from 'react';
import { Alert, Col, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBottleWater } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import CropImage from '../../../components/Images/CropImageSVG';
import { PRODUCTION_REPORT_SEARCH } from './ProductionReportChecks';
import ReportCheckSearchButton from './ReportCheckSearchButton';
import { SelectFieldType } from '../../../components/Select/select.types';
import MistakesMenu from './MistakesMenu';

const ReportCheckRow: FC<{
  crop: any;
  showModal: (el: any) => void;
  onClickBBS: (value: string | null) => void;
  handleChangeState: (value: any, flag: string, id: string) => void;
  mistakesList: Array<SelectFieldType>;
  onSendMistakes: (el: string) => void;
  isLoading: boolean;
  isViewer: boolean;
  selectedCrops: Array<string>;
  setSelectedCrops: Dispatch<SetStateAction<Array<string>>>;
}> = ({
  crop,
  showModal,
  onClickBBS,
  handleChangeState,
  mistakesList,
  onSendMistakes,
  isLoading,
  isViewer,
  selectedCrops,
  setSelectedCrops,
}) => {
  const styles: Record<string, React.CSSProperties> = {
    icon: { color: 'lightgray', height: '75%' },
  };

  const onClickCS = (el: string | null) => {
    const link = `/clusters${PRODUCTION_REPORT_SEARCH}&brandbank_uuid=${el}`;
    window.open(link, '_blank');
  };

  const isSelected: boolean = selectedCrops.indexOf(crop.id) !== -1;

  const onSelectCrop = useCallback(
    (cropId) => {
      if (selectedCrops.indexOf(cropId) !== -1) {
        const updSelectedCrops = selectedCrops.filter((el) => el !== cropId);
        setSelectedCrops(updSelectedCrops);
      } else {
        setSelectedCrops([...selectedCrops, cropId]);
      }
    },
    [selectedCrops, setSelectedCrops],
  );

  return (
    <>
      <div
        className={classNames({
          auto_checked: crop.flag_auto_checked,
        })}
        data-testid={`report-row__${crop.id}`}>
        <Row>
          <Col xl="3" lg="4" md="4" sm="5" xs="12" className="d-flex">
            {!isViewer && (
              <div className="d-flex justify-content-center align-items-center pr-2 mr-1">
                <input
                  type="checkbox"
                  id={`isSelected-${crop.id}`}
                  data-testid={`isSelected-${crop.id}`}
                  checked={isSelected}
                  onChange={() => onSelectCrop(crop.id)}
                />
              </div>
            )}
            <div
              className="d-flex justify-content-center align-items-center"
              data-testid={`report-row__img-${crop.id}`}>
              {!crop.loadingImage ? (
                <>
                  {!crop.isLoadingImageError && (
                    <a
                      href="#"
                      onClick={() => showModal(crop)}
                      style={{ cursor: 'pointer' }}
                      data-testid={`report-row__image-${crop.id}`}>
                      <CropImage
                        width="200px"
                        height="200px"
                        source={crop.preReviewCrop?.source.crop}
                        rectangle={crop.preReviewCrop?.source.rect}
                      />
                    </a>
                  )}
                  {crop.isLoadingImageError && (
                    <div
                      style={{ width: '200px', height: '200px' }}
                      className="card d-flex justify-content-center align-items-center mb-0">
                      <FontAwesomeIcon
                        style={styles.icon}
                        icon={faBottleWater}
                      />
                    </div>
                  )}
                </>
              ) : (
                <Spinner animation="border" role="status" />
              )}
            </div>
          </Col>
          <Col xl="9" lg="8" md="8" sm="7" xs="12">
            <Row>
              <Col xl="6" sm="12">
                <ReportCheckSearchButton
                  value={crop.tagged}
                  onClick={onClickBBS}
                  label="Tagged"
                  isBBS
                />
                <ReportCheckSearchButton
                  value={crop.prediction}
                  onClick={onClickBBS}
                  label="Prediction"
                  isBBS
                />
                <ReportCheckSearchButton
                  value={crop.tagged_brandbank_uuid}
                  onClick={onClickCS}
                  label="Tagged brandbank uuid"
                />
                <ReportCheckSearchButton
                  value={crop.prediction_brandbank_uuid}
                  onClick={onClickCS}
                  label="Prediction brandbank uuid"
                />
                <p className="card-category">Score: {crop.score}</p>
                <p className="card-category">FS label id: {crop.fs_label_id}</p>
                <p className="card-category">Photo id: {crop.photo_id}</p>
                <p className="card-category">
                  Created crop job id: {crop.created_crop_job_ids.join(', ')}
                </p>
                <p className="card-category">
                  Labeler(s): {crop.labelers.join(', ')}
                </p>
                <p className="card-category mb-2">Rewiever: {crop.reviewer}</p>
              </Col>
              <Col xl="6" sm="12">
                <div
                  className="mt-1 ml-n1"
                  data-testid={`report-row__mistakes-${crop.id}`}>
                  {!isViewer && crop.isLoadingMistake && (
                    <Spinner animation="border" role="status" />
                  )}
                  {!isViewer && !crop.isLoadingMistake && (
                    <div>
                      {crop.mistakeSuccessMessage && (
                        <Alert variant="success">success</Alert>
                      )}
                      {crop.mistakeErrorMessage && (
                        <Alert variant="danger">error</Alert>
                      )}
                      <MistakesMenu
                        crop={crop}
                        isLoading={isLoading}
                        onSendMistakes={() => onSendMistakes(crop.id)}
                        handleChangeState={handleChangeState}
                        mistakesList={mistakesList}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <hr />
    </>
  );
};

export default ReportCheckRow;
