import React, { FC, MouseEventHandler } from 'react';

import { createPortal } from 'react-dom';
import { CloseButton } from 'react-bootstrap';
import styles from './scss/bulk-container.module.scss';

export interface BulkActionsMenuContainerProps {
  children: React.ReactNode;
  title: string;
  onClose: MouseEventHandler<HTMLButtonElement> | undefined;
}

export const BulkActionsMenuContainer: FC<BulkActionsMenuContainerProps> = ({
  title,
  children,
  onClose,
}) => {
  const container = document.querySelector('.wrapper');
  if (!container) return null;
  return createPortal(
    <div className={`new-ui bulk-menu__wrapper ${styles.bulkMenu__container}`}>
      <div className={styles.bulkMenu__menu}>
        <div className={styles.bulkMenu__info}>{title}</div>
        <div className={styles.bulkMenu__line}>{children}</div>
        <div>
          <CloseButton
            className={`menu-action ${styles.bulkMenu__close}`}
            onClick={onClose}
          />
        </div>
      </div>
    </div>,
    container,
  );
};

export default BulkActionsMenuContainer;
