import React from 'react';
import { useUserContext } from '../../../contexts/Users';
import { StationStateContextProvider } from '../../../contexts/StationStateContext';
import CleaningStation from './Cleaning';

const CleaningStationWrapper = (): JSX.Element | undefined => {
  const { isExternal } = useUserContext();

  // TODO: Why is this needed?
  if (isExternal) return;

  return (
    <StationStateContextProvider>
      <div className="new-ui station-container">
        <CleaningStation />
      </div>
    </StationStateContextProvider>
  );
};

CleaningStationWrapper.displayName = 'CleaningStationWrapper';

export default CleaningStationWrapper;
