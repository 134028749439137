import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { setPauseResumeWork } from '../datalayer/dataLayer';
import { useUserContext } from './Users';

export interface PauseWorkContextProps {
  paused: boolean;
  togglePauseWork: () => void;
}

const PauseWorkContext = createContext<PauseWorkContextProps>({
  paused: false,
  togglePauseWork: () => {},
});

const PauseWorkContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { workPaused: beWorkPaused } = useUserContext();
  const [prevBEWorkPaused, setPrevBEWorkPaused] = useState(beWorkPaused);
  const [paused, setPaused] = useState(beWorkPaused);

  if (beWorkPaused !== prevBEWorkPaused) {
    setPrevBEWorkPaused(beWorkPaused);
    setPaused(beWorkPaused);
  }

  const togglePauseWork = useCallback(() => {
    setPauseResumeWork(!paused).then(() => setPaused(!paused));
  }, [paused]);

  const value = useMemo(
    () => ({ paused, togglePauseWork }),
    [paused, togglePauseWork],
  );
  return (
    <PauseWorkContext.Provider value={value}>
      {children}
    </PauseWorkContext.Provider>
  );
};

export const usePauseWorkContext: () => PauseWorkContextProps = () => {
  const context = useContext(PauseWorkContext);
  if (!context) {
    throw new Error('usePauseWorkContext must be used within a StateProvider');
  }
  return context;
};

export default PauseWorkContextProvider;
