import React, { useState } from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';
import classNames from 'classnames';
import FastLaneGPT from './FastLaneGPT';
import FastLaneGPTManualSearch from './FastLaneGPTManualSearch';
import { useStationStateContext } from '../../../contexts/StationStateContext';

const FastLaneGPTWrapper = (): React.ReactElement => {
  const { selectedJobType, errorMessage, clusterData } =
    useStationStateContext();
  const [activeTab, setActiveTab] = useState<string>('tab_gpt');
  const disableTabs = !selectedJobType || !!errorMessage;

  const [prevClusterId, setPrevClusterId] = useState(clusterData?.id);
  if (clusterData?.id !== prevClusterId) {
    setPrevClusterId(clusterData?.id);
    setActiveTab('tab_gpt');
  }
  return (
    <Card className="d-flex flex-grow-1 w-100">
      <Card.Body className="p-3 d-flex flex-grow-1 flex-column">
        <Nav
          variant="tabs"
          className="d-flex gap-3 pt-2 mt-n3 mx-n3 mb-0 navigation-tabs"
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(key || 'tab_gpt')}>
          <Nav.Item className="flex-fill text-center mr-0">
            <Nav.Link eventKey="tab_gpt" disabled={disableTabs}>
              Suggested Labels
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="flex-fill text-center mr-0 ">
            <Nav.Link eventKey="tab_manual" disabled={disableTabs}>
              Manual Search
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content className="mt-4 d-flex flex-grow-1 flex-column h-100">
          <Tab.Pane
            eventKey="tab_gpt"
            active={activeTab === 'tab_gpt'}
            className={classNames('flex-grow-1', 'flex-column', {
              'd-flex': activeTab === 'tab_gpt',
              disabled: disableTabs,
            })}>
            <FastLaneGPT />
          </Tab.Pane>
          <Tab.Pane
            eventKey="tab_manual"
            active={activeTab === 'tab_manual'}
            className={classNames('flex-grow-1', 'flex-column', {
              'd-flex': activeTab === 'tab_manual',
            })}>
            <FastLaneGPTManualSearch />
          </Tab.Pane>
        </Tab.Content>
      </Card.Body>
    </Card>
  );
};
FastLaneGPTWrapper.displayName = 'FastLaneGPTWrapper';
export default FastLaneGPTWrapper;
