import React, { FC } from 'react';
import { Chance } from 'chance';
import Graph from './Graph';
import GraphsLoading from './GraphsLoading';
import GraphContainer, { PerfItem } from './GraphContainer';

const TeamGraphs: FC = () => {
  const piRand = new Chance('team pi');
  const perfItems: PerfItem[] = [
    {
      label: 'Clusters done this month',
      value: piRand.integer({ min: 5000, max: 10000 }),
      percentDelta: piRand.integer({ min: 1, max: 90 }),
    },
    {
      label: 'Average daily score per hour',
      value: piRand.integer({ min: 20, max: 200 }),
      percentDelta: piRand.integer({ min: -50, max: -10 }),
    },
    {
      label: 'Highest daily score per hour',
      value: piRand.integer({ min: 20, max: 200 }),
      percentDelta: piRand.integer({ min: 1, max: 90 }),
    },
    {
      label: 'Lowest daily score per hour',
      value: piRand.integer({ min: 20, max: 200 }),
      percentDelta: piRand.integer({ min: -50, max: -10 }),
    },
  ];
  return (
    <GraphsLoading empty={false} loading={false}>
      <GraphContainer label="Team’s Efficiency" perfItems={perfItems}>
        <Graph label="Agregated Efficiency" />
        <Graph label="Efficiency per team member" />
        <Graph label="Top Contributors" />
      </GraphContainer>
      <GraphContainer label="Team’s Quality" perfItems={perfItems}>
        <Graph label="Agregated Quality" />
        <Graph label="Quality per team member" />
        <Graph label="Top Contributors" />
      </GraphContainer>
    </GraphsLoading>
  );
};
TeamGraphs.displayName = 'TeamGraphs';

export default TeamGraphs;
