import CustomSpinner from 'components/CustomSpinner/CustomSpinner';
import { SearchUserIcon } from 'components/Icons';
import React, { FC } from 'react';
import { Card } from 'react-bootstrap';

interface GraphsLoadingProps {
  loading: boolean;
  empty: boolean;
}

const GraphsLoading: FC<GraphsLoadingProps> = ({
  loading,
  empty,
  children,
}) => {
  if (loading)
    return (
      <Card className="d-flex w-100">
        <Card.Body className="d-flex flex-column flex-grow-1 align-items-center justify-content-center">
          <CustomSpinner />
        </Card.Body>
      </Card>
    );
  if (empty)
    return (
      <Card className="d-flex w-100">
        <Card.Body className="d-flex flex-column d-flex flex-column flex-grow-1 align-items-center justify-content-center">
          <SearchUserIcon />
          <div className="main-text">
            Set filters to see your efficiency stats
          </div>
        </Card.Body>
      </Card>
    );
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
GraphsLoading.displayName = 'GraphsLoading';

export default GraphsLoading;
