/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable  react/no-unstable-nested-components */
import React, { FC } from 'react';
import ReactSelect, { components, ControlProps, GroupBase } from 'react-select';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import Icon, { IconName } from '../Icons';

export const SELECT_WITH_ICON_STYLE: StylesConfig<
  unknown,
  boolean,
  GroupBase<unknown>
> = {
  control: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    minWidth: 158,
    maxWidth: 850,
    minHeight: 32,
    outline: 'none',
    boxShadow: 'none',
    borderRadius: '34px',
    cursor: 'pointer',
    backgroundColor:
      state.hasValue || state.menuIsOpen || state.isFocused
        ? '#f1f6f6'
        : '#ffffff',
    border: `1px solid ${
      state.hasValue || state.menuIsOpen || state.isFocused
        ? '#4E938E'
        : '#BEBFC1'
    }`,
    '&:hover': {
      borderColor: state.hasValue ? '#1B7899' : '#4E938E',
    },
    borderColor: '#BEBFC1', // $disabled
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '6px 8px',
    color: '#929597', // $neutral-50
    '& svg': {
      fill: '#929597', // $neutral-50
    },
    '&:hover': {
      color: '#1B7899',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    padding: '1px',
    boxShadow: 'none',
    border: '1px solid #4E938E',
    borderRadius: '10px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    color: '#262b2f',
    cursor: 'pointer',
    backgroundColor: state.isSelected ? '#F1F6F6' : '#fff',
    '&:hover': {
      backgroundColor: '#F1F6F6',
    },
  }),
};

type SelectWithIconProps = {
  icon: IconName;
  placeholder: string;
} & React.ComponentProps<typeof ReactSelect>;

// TODO: type me properly (see onChange issues)
const Control: FC<
  ControlProps<any, boolean, GroupBase<any>> & { icon: IconName }
> = ({ children, icon, ...props }) => (
  <components.Control {...props}>
    <Icon name={icon} className="ml-2" />
    {children}
  </components.Control>
);

const SelectWithIcon: FC<SelectWithIconProps> = ({
  icon,
  placeholder,
  ...selectProps
}) => {
  return (
    <ReactSelect
      styles={SELECT_WITH_ICON_STYLE}
      components={{ Control: (props) => <Control {...props} icon={icon} /> }}
      placeholder={placeholder}
      {...selectProps}
    />
  );
};

export default SelectWithIcon;
