import React, { useState } from 'react';
import { Card, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SpinnerButton from '../../../components/Buttons/SpinnerButton';
import { CUSTOM_INPUT_STYLES } from '../../../components/StylesConstants';

function ProductionReportChecksFilters({ filterCallback, isLoadingSearch }) {
  const [searchFsLabelId, setSearchFsLabelId] = useState('');
  const [searchPrediction, setSearchPrediction] = useState('');
  const [searchTagged, setSearchTagged] = useState('');

  const onClick = () => {
    filterCallback(searchFsLabelId, searchPrediction, searchTagged);
  };

  const buttonDisabled = !searchFsLabelId && !searchPrediction && !searchTagged;

  return (
    <Card className="mb-4">
      <Card.Header>
        <Card.Title as="h5">Additional Filters:</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row className="mt-n3 pl-3 pb-2">
          <div className="mr-3 mt-3">
            <Form.Control
              style={CUSTOM_INPUT_STYLES}
              disabled={isLoadingSearch}
              type="search"
              onChange={(event) => setSearchFsLabelId(event.target.value)}
              className="form-control"
              placeholder="FS Label Id"
              name="searchFsLabelId"
              value={searchFsLabelId}
              data-testid="form-control_searchFsLabelId"
            />
          </div>
          <div className="mr-3 mt-3">
            <Form.Control
              style={CUSTOM_INPUT_STYLES}
              disabled={isLoadingSearch}
              type="search"
              onChange={(event) => setSearchPrediction(event.target.value)}
              className="form-control"
              placeholder="Prediction"
              name="searchPrediction"
              value={searchPrediction}
              data-testid="form-control_searchPrediction"
            />
          </div>
          <div className="mr-3 mt-3">
            <Form.Control
              style={CUSTOM_INPUT_STYLES}
              disabled={isLoadingSearch}
              type="search"
              onChange={(event) => setSearchTagged(event.target.value)}
              className="form-control"
              placeholder="Tagged"
              name="searchTagged"
              value={searchTagged}
              data-testid="form-control_Tagged"
            />
          </div>
          <div className="mt-3 mr-3">
            <SpinnerButton
              isLoading={isLoadingSearch}
              disabled={buttonDisabled}
              onClick={onClick}
              value="Search"
              style={{ minHeight: 50 }}
            />
          </div>
        </Row>
      </Card.Body>
    </Card>
  );
}

ProductionReportChecksFilters.propTypes = {
  filterCallback: PropTypes.func,
  isLoadingSearch: PropTypes.bool,
};

ProductionReportChecksFilters.defaultProps = {
  isLoadingSearch: false,
};

export default ProductionReportChecksFilters;
