import { getData, patchDataAuthorizedWithParams } from './base';

export const updateAuditReport = (id, params) => {
  const pathname = `audit_reports/crop/${id}/`;
  return patchDataAuthorizedWithParams(pathname, params);
};

export const getAuditReportData = (type) => {
  const pathname = `audit_reports/${type}/`;
  return getData(pathname);
};

export const getAuditReport = (params) => {
  const pathname = `audit_reports/`;
  return getData(pathname, params);
};

export const getAuditReportDetails = (id, params) => {
  const pathname = `audit_reports/${id}/sku/`;
  return getData(pathname, params);
};

export const getAuditReportLabel = (id, params) => {
  const pathname = `audit_reports/sku/${id}/crops/`;
  return getData(pathname, params);
};

export const getStatsPerDay = (id, params) => {
  const pathname = `audit_reports/${id}/stats_per_day/`;
  return getData(pathname, params);
}

export const getAuditReportMistakeTypes= (params) => {
  const pathname = `audit_reports/mistake_types/`;
  return getData(pathname, params);
};

export const getAuditReportBBS= (params) => {
  const pathname = `labels/`;
  return getData(pathname, params);
};

export const getStatsFlagsAndMistakes = (id, params) => {
  const pathname = `audit_reports/${id}/stats_flags_and_mistakes/`;
  return getData(pathname, params);
}

