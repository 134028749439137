/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import SingleCard from './SingleCard';

interface CardsListProps {
  dataLength: number;
  next: () => void;
  hasMore: boolean;
  scrollableTarget: string;
  items: any;
  onCardClick?: (item: any) => void;
  activeCardId: string | undefined;
}
const CardsList: FC<CardsListProps> = ({
  dataLength = 1,
  next,
  hasMore,
  scrollableTarget,
  items,
  onCardClick,
  activeCardId,
}) => {
  return (
    <InfiniteScroll
      dataLength={dataLength}
      next={next}
      hasMore={hasMore}
      loader={<CustomSpinner />}
      style={{ overflow: 'hidden' }}
      scrollableTarget={scrollableTarget}>
      {items.map((item: any) => (
        <div
          role="button"
          onClick={() => onCardClick && onCardClick(item)}
          tabIndex={0}
          className="py-2 label-card__wrapper"
          key={`suggested_item_${item.id}`}>
          <SingleCard
            activeCard={item.id === activeCardId}
            key={`suggested_card_${item.id}`}
            photos={item?.photos}
            title={item?.name}
          />
        </div>
      ))}
    </InfiniteScroll>
  );
};
export default CardsList;
