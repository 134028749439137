/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import Icon, { IconName } from 'components/Icons';
import React, { ComponentProps, FC } from 'react';
import { Link } from 'react-router-dom';

interface IconLinkButtonProps extends Omit<ComponentProps<typeof Link>, 'to'> {
  icon: IconName;
  to?: string;
  className?: string;
  disabled?: boolean;
  classNameIcon?: string;
}
const IconLinkButton: FC<IconLinkButtonProps> = (props) => {
  const {
    icon,
    to,
    disabled,
    children,
    className = '',
    classNameIcon = '',
    ...restProps
  } = props;

  return (
    <Link
      to={to || '#'}
      {...restProps}
      className={classNames(`icon-link-button ${className}`, { disabled })}>
      <Icon name={icon} className={classNameIcon} />
      {children}
    </Link>
  );
};

export default IconLinkButton;
