import React, {useCallback, useEffect, useState} from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getAuditReport } from '../../api/auditReport';
import Table from '../../components/Table/Table';
import ProductionReportFilters from './ProductionReportFilters';
import { PAGE_SIZE } from '../../components/Paginators/ListPaginator';
import ConfirmModal from '../../modals/ConfirmModal';

function DownloadModal({ show, setShow, data }) {
  return (
    <ConfirmModal
      show={show}
      onCancel={() => setShow(false)}
      header="Downloads Report(s)"
      size="xl"
    >
      <ul>
        {data &&
          data.map((el) => {
            return (
              <li
                key={el.file_name}
                onClick={() => window.open(el.file_path, '_blank')}
              >
                <a className="text-info" href="#">
                  {el.file_name}
                </a>
              </li>
            );
          })}
      </ul>
    </ConfirmModal>
  );
}

function ProductionReports() {
  const [rowNames, setRowNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [page, setPage] = useState(1);
  const [parameters, setParameters] = useState({ ordering: '-created_at' });
  const [error, setError] = useState(false);
  const [showDownloadReports, setShowDownloadReports] = useState(false);

  const [downloadReports, setDownloadReports] = useState(null);

  const columnNames = [
    'Client',
    'Type',
    'Containers',
    'Model',
    'Th. Model',
    'Th. Analysis',
    'date from',
    'date to',
    'Created',
    'Period',
    'Images',
    'Crops',
    'Accuracy',
    'Errors count',
    'SKU count',
    'Download Report(s)',
  ];

  const onClickDownload = (item) => {
    setDownloadReports(item.reports_data);
    setShowDownloadReports(true);
  };

  const setAuditReport = useCallback((params) => {
    getAuditReport({ page_size: PAGE_SIZE, ...params }).then(
      async (response) => {
        const status = await response.status;
        if (status === 200) {
          await response
            .json()
            .then((source) => {
              const newRowNames = [];
              source.results.forEach((item) => {
                newRowNames.push({
                  Client: item.client,
                  Type: item?.report_type || 'unset',
                  Containers: item.containers
                    ? item.containers.map((el, i) => {
                        if (i === 0) {
                          return el;
                        }
                        return (
                          <>
                            ,<br />
                            {el}
                          </>
                        );
                      })
                    : [],
                  Model: item.model,
                  'Th. Model': item.threshold_model,
                  'Th. Analysis': item.threshold_analysis,
                  'date from': moment(item.date_from).format(
                    'YYYY-MM-DD HH:mm:ss',
                  ),
                  'date to': moment(item.date_to).format('YYYY-MM-DD HH:mm:ss'),
                  Created: moment(item.created_at).format(
                    'YYYY-MM-DD HH:mm:ss',
                  ),
                  Period: item.period,
                  Images: item.images_count,
                  Crops: item.crops_count,
                  Accuracy: `${(item.accuracy * 100).toFixed(2)}%`,
                  'Errors count': (
                    <>
                      {item.crops_checked_count} / {item.error_count || '0'}
                      <br />
                      <span className="text-muted">
                        {item.error_count
                          ? (
                              100 *
                              (item.crops_checked_count / item.error_count)
                            ).toFixed(2)
                          : '100'}
                        %
                      </span>
                    </>
                  ),
                  'SKU count': (
                    <Link
                      to={{
                        pathname: `/audit-report-details/${item.id}`,
                        state: { crop: item },
                      }}
                    >
                      <Button variant="default">
                        {item.sku_count} <i className="nc-icon nc-stre-right" />
                      </Button>{' '}
                    </Link>
                  ),
                  'Download Report(s)': (
                    <Button
                      className="text-center"
                      onClick={() => onClickDownload(item)}
                      variant="default"
                      data-testid={`download-report_${item.id}`}
                    >
                      <i className="nc-icon nc-cloud-download-93" />
                    </Button>
                  ),
                });
              });
              setRowNames(newRowNames);
              setCount(source.count);
              setNext(source.next);
              setPrevious(source.previous);
              setError(false);
            })
            .catch(() => setError(true));
        } else {
          setError(true);
        }
        setIsLoading(false);
      },
    );
  }, []);

  useEffect(() => {
    setAuditReport({ page: 1, ...parameters });
  }, [parameters, setAuditReport]);

  const loadData = (params) => {
    setPage(params.page);
    setAuditReport({ page: params.page, ...parameters });
  };

  const filterCallback = (
    selectedClient,
    selectedModel,
    selectedType,
    selectedContainer,
    selectedSorting,
  ) => {
    setIsLoading(true);
    const newParameters = { ordering: selectedSorting };
    if (selectedClient?.value) newParameters.client = selectedClient?.value;
    if (selectedModel?.value) newParameters.model = selectedModel?.value;
    if (selectedType?.value) newParameters.report_type = selectedType?.value;
    if (selectedContainer?.value)
      newParameters.containers = selectedContainer?.value;
    setPage(1);
    setParameters(newParameters);
    setAuditReport({ page: 1, ...newParameters });
  };

  return (
    <>
      <ProductionReportFilters
        filterCallback={filterCallback}
        isLoading={isLoading}
      />
      {error ? (
        <div className="alert alert-danger">Sorry, something went wrong</div>
      ) : (
        <Table
          loadData={loadData}
          count={count}
          page={page}
          next={next}
          previous={previous}
          rowNames={rowNames}
          columnNames={columnNames}
          isLoading={isLoading}
        />
      )}
      <DownloadModal
        show={showDownloadReports}
        setShow={setShowDownloadReports}
        data={downloadReports}
      />
    </>
  );
}

export default ProductionReports;
