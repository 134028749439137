import { Button } from 'react-bootstrap';
import React, { useEffect } from 'react';
import TabView, { TabViewItem } from 'components/TabView/TabView';
import CropsList from 'components/CropLists/CropsList';
import Switch from 'components/Buttons/Switch';
import { cropsPlural } from 'utils';
import ConfirmModal from 'modals/ConfirmModal';
import CropActionsMenu from './CropActionsMenu';
import CropView from './CropView';
import CropShelfView from './CropSelfView';
import StationControlPanel from '../../../components/StationControlPanel/StationControlPanel';
import {
  MAIN_CLUSTER_ID,
  useStationStateContext,
} from '../../../contexts/StationStateContext';

import { useUserContext } from '../../../contexts/Users';
import useSettings from '../../../contexts/SettingsContext';
import { SubStateType } from '../../../enumsSubState';

const CleaningStation = (): JSX.Element => {
  const {
    clusterData,
    clusters,
    setShowRemoveClusterModal,
    performClusterAction,
    setStationTypeContextState,
    selectedClusterId,
    setSelectedClusterId,
    clusterCrops,
    isAutoLoadOn,
    cropViewId,
    onSelectLabelerMode,
    showRemoveClusterModal,
  } = useStationStateContext();

  const { isLabelerMode } = useSettings();

  const { isLabeler } = useUserContext();
  useEffect(() => {
    setStationTypeContextState(SubStateType.CLEANING);
  }, [setStationTypeContextState]);

  return (
    <>
      <StationControlPanel />

      <TabView
        activeKey={selectedClusterId}
        extraContent={
          clusters.length > 1 ? (
            <Button
              variant="outline-secondary"
              onClick={() => performClusterAction('acceptAllNewClusters')}>
              Accept All New Clusters
            </Button>
          ) : null
        }
        onSelect={(key) => setSelectedClusterId(key || MAIN_CLUSTER_ID)}>
        {clusters.map(({ id, name }) => {
          const cropsCount = (clusterCrops[id] || []).length;
          return (
            <TabViewItem
              tabKey={id}
              label={`${name} (${cropsCount} ${cropsPlural(cropsCount)})`}
              key={id}>
              <div className="d-flex flex-grow-1 flex-column p-0">
                <CropsList
                  crops={clusterCrops[id] || []}
                  empty={!clusterData?.id}
                  paused={!isAutoLoadOn}
                  sortEnabled={id === MAIN_CLUSTER_ID}
                />
              </div>
            </TabViewItem>
          );
        })}
      </TabView>
      <CropActionsMenu />
      <div className="d-flex mb-2 justify-content-end labeler-switch">
        {!isLabeler && !cropViewId && (
          <Switch
            name="1"
            checked={isLabelerMode}
            setChecked={onSelectLabelerMode}
            label="Labeler View Mode"
          />
        )}
      </div>
      <CropView crops={clusterCrops[selectedClusterId]} />
      <CropShelfView />
      <ConfirmModal
        header="Remove Cluster"
        confirmLabel="Remove Cluster"
        cancelLabel="Cancel"
        show={showRemoveClusterModal}
        onCancel={() => setShowRemoveClusterModal(false)}
        onConfirm={() => {
          setShowRemoveClusterModal(false);
          performClusterAction('removeCluster');
        }}>
        Do you want to remove cluster?
      </ConfirmModal>
    </>
  );
};
CleaningStation.displayName = 'CleaningStation';

export default CleaningStation;
