import React, { FC } from 'react';

const Graph: FC<{ label?: string }> = ({ label }) => {
  return (
    <div className="d-flex flex-column">
      {label && <div className="fw-500 mb-2">{label}</div>}
      <div className="mb-3">GRAPH HERE</div>
    </div>
  );
};
Graph.displayName = 'Graph';

export default Graph;
