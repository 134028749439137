import {
  API_DATE_FORMAT,
  getIndividualPerformanceUnits,
  getThersholds,
  Station,
} from 'api/performanceReports';
import moment from 'moment';
import {
  AggregatedEfficiency,
  AggregatedQuality,
  PerformanceUnitResult,
  PerformanceUnitTO,
  ThresholdsTO,
} from 'api/api.types';
import { processResponse } from './dataLayer';

export enum IndividualGraphKind {
  Efficiency = 'efficiency',
  Quality = 'quality',
}

export type PerformanceStats = {
  total: number;
  totalDelta: number;
  average: number;
  averageDelta: number;
  min: number;
  minDelta: number;
  max: number;
  maxDelta: number;
};

const delta = (prev: number, cur: number) =>
  prev === 0 ? 100 : Math.round((100 * (cur - prev)) / prev);

export const calcPerformanceStats = (
  results: PerformanceUnitResult[],
  field: IndividualGraphKind,
) => {
  const count = results.length;
  const res = results.reduce(
    (acc, cur) => {
      const current = Object.values(cur)[0]?.[`aggregated_${field}`];
      const perHour = current
        ? current[
            `${field}_per_hour` as keyof (
              | AggregatedEfficiency
              | AggregatedQuality
            )
          ]
        : undefined;
      const perDay = current
        ? current[
            `${field}_per_day` as keyof (
              | AggregatedEfficiency
              | AggregatedQuality
            )
          ]
        : undefined;
      if (perHour === undefined || perDay === undefined)
        throw new Error(`Bad ${field} data format`);
      return {
        total: acc.total + perDay,
        average: acc.average + perHour / count,
        min: Math.min(acc.min, perHour),
        max: Math.max(acc.max, perHour),
      };
    },
    {
      total: 0,
      average: 0,
      min: Number.MAX_SAFE_INTEGER,
      max: Number.MIN_SAFE_INTEGER,
    },
  );
  res.average = Math.round(res.average);
  return res;
};

type PThresholds = {
  efficiency: number | undefined;
  quality: number | undefined;
};

type PStats = Partial<Record<IndividualGraphKind, PerformanceStats>>;

type PGraphItem = {
  date: Date;
  value: number;
};
type PGraphData = Partial<Record<IndividualGraphKind, PGraphItem[]>>;

export const getIndividualPerformance = async (
  jobType: number,
  station: Station,
  date: Date,
): Promise<{
  stats: PStats;
  thresholds: PThresholds;
  graphData: PGraphData;
}> => {
  const [curData, prevData, thresholdData] = await Promise.all([
    // Cur month
    getIndividualPerformanceUnits(
      jobType,
      station,
      moment(date).date(1),
      moment(date).date(1).add(1, 'month').subtract(1, 'day'),
    ).then((r) => processResponse<PerformanceUnitTO>(r)),
    // TODO: Cache previous month data
    // Prev month
    getIndividualPerformanceUnits(
      jobType,
      station,
      moment(date).date(1).subtract(1, 'month'),
      moment(date).date(1).subtract(1, 'day'),
    ).then((r) => processResponse<PerformanceUnitTO>(r)),
    getThersholds(jobType, station).then((r) =>
      processResponse<ThresholdsTO>(r),
    ),
  ]);
  const stats: PStats = {};
  Object.values(IndividualGraphKind).forEach((kind) => {
    const prevStats = calcPerformanceStats(prevData.results, kind);
    const curStats = calcPerformanceStats(curData.results, kind);

    stats[kind] = {
      ...curStats,
      totalDelta: delta(prevStats.total, curStats.total),
      averageDelta: delta(prevStats.average, curStats.average),
      minDelta: delta(prevStats.min, curStats.min),
      maxDelta: delta(prevStats.max, curStats.max),
    };
  });
  const thresholds = {
    [IndividualGraphKind.Efficiency]: thresholdData.results.find(
      (t) =>
        t.job_type_id === jobType &&
        t.substate === station &&
        t.type === IndividualGraphKind.Efficiency.toUpperCase(),
    )?.value,
    [IndividualGraphKind.Quality]: thresholdData.results.find(
      (t) =>
        t.job_type_id === jobType &&
        t.substate === station &&
        t.type === IndividualGraphKind.Quality.toUpperCase(),
    )?.value,
  };

  const graphData: PGraphData = {};
  Object.values(IndividualGraphKind).forEach((kind) => {
    graphData[kind] = curData.results.map((r) => {
      if (!Object.keys(r).length) throw new Error(`Bad ${kind} data format`);
      const [sDate, values] = Object.entries(r)[0];
      const value = values[`aggregated_${kind}`];
      return {
        date: moment(sDate, API_DATE_FORMAT).toDate(),
        value:
          value[
            `${kind}_per_hour` as keyof (
              | AggregatedEfficiency
              | AggregatedQuality
            )
          ],
      };
    });
  });
  return { stats, thresholds, graphData };
};
