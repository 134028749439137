import {
  getData,
  patchDataAuthorized,
  putDataWithParams,
  patchDataAuthorizedWithParams,
  postDataAuthorizedWithParams,
  putJsonDataAuthorizedWithParams,  postDataAuthorized,
} from './base';

export const getExtendedClusters = (props) => {
  const pathname = 'clusters/internal_search/';
  return getData(pathname, props);
};

export const getBaseClusters = (props) => {
  const pathname = 'clusters/external_search/';
  return getData(pathname, props);
};

export const assignAsLabeler = (clusterId, props) => {
  let pathname = 'clusters/';
  pathname = `${pathname}${clusterId}/assign_as_labeler/`;
  return patchDataAuthorizedWithParams(pathname, props);
};

export const assignAsReviewer = (clusterId, props) => {
  let pathname = 'clusters/';
  pathname = `${pathname}${clusterId}/assign_as_reviewer/`;
  return patchDataAuthorizedWithParams(pathname, props);
};

export const removeCluster = (clusterId, props) => {
  let pathname = 'clusters/';
  pathname = `${pathname}${clusterId}/remove_cluster/`;
  return patchDataAuthorizedWithParams(pathname, props);
};

export const removeSelectedCrops = (clusterId, props, body) => {
  let pathname = 'clusters/';
  pathname = `${pathname}${clusterId}/remove_crops/`;
  return patchDataAuthorizedWithParams(pathname, props, body);
};

export const rejectClusterWithComment = (clusterId, props) => {
  let pathname = 'clusters/';
  pathname = `${pathname}${clusterId}/reject/`;
  return patchDataAuthorizedWithParams(pathname, props);
};

export const moveToNewCluster = (clusterId, props, body) => {
  let pathname = 'clusters/';
  pathname = `${pathname}${clusterId}/new_cluster/`;
  return postDataAuthorizedWithParams(pathname, props, body);
};

export const rejectCluster = (clusterId) => {
  let pathname = 'clusters/';
  pathname = `${pathname}${clusterId}/reject/`;
  return patchDataAuthorized(pathname);
};

export const sendCluster = (clusterId) => {
  let pathname = 'clusters/';
  pathname = `${pathname}${clusterId}/?substate=CLEANING-QC`;
  return patchDataAuthorized(pathname);
};

export const sendClusterWithComment = (clusterId, props) => {
  let pathname = 'clusters/';
  pathname = `${pathname}${clusterId}/`;
  return patchDataAuthorizedWithParams(pathname, {substate: 'CLEANING-QC' , ...props});
};

export const acceptCluster = (clusterId) => {
  let pathname = 'clusters/';
  pathname = `${pathname}${clusterId}/clean/`;
  return patchDataAuthorized(pathname);
};

export const getClusterHistory = (clusterId) => {
  const pathname = `clusters/${clusterId}/history/`;
  return getData(pathname);
};

export const getClusterDetails = (clusterId, props) => {
  const pathname = `clusters/${clusterId}/`;
  return getData(pathname, props);
};

export const getClusterSimilar = (clusterId, props) => {
  const pathname = `clusters/${clusterId}/similar`;
  return getData(pathname, props);
};

export const getPreFiltering = (props) => {
  const pathname = `clusters/assign_labeler/`;
  return putDataWithParams(pathname, props);
};

export const postSelectCluster = (id, props) => {
  const pathname = `clusters/${id}/select/`;
  return patchDataAuthorized(pathname, props);
};

export const postDiscardCluster = (id, props) => {
  const pathname = `clusters/${id}/discard/`;
  return patchDataAuthorized(pathname, props);
};

export const assignUser = (user, props) => {
  const pathname = `clusters/assign_${user}/`;
  return putDataWithParams(pathname, props);
};

export const reProcess = (body, props) => {
  const pathname = 'clusters/re-process/';
  return putJsonDataAuthorizedWithParams(pathname, body, props);
};

export const getBBox = (id) => {
  const pathname = `clusters/points/${id}`;
  return getData(pathname);
};

export const getClustersRejections = (id) => {
  const pathname = `clusters/${id}/rejections/`;
  return getData(pathname);
}

export const getClusterStatistics = (props) => {
  const pathname = 'clusters/statistics/';
  return getData(pathname, props);
};

export const getGPTProposals = (id, props) => {
  const pathname = `clusters/gpt/${id}/proposals/`;
  return getData(pathname, props);
}

export const markCrops = (clusterId, props, body) => {
  let pathname = 'clusters/';
  pathname = `${pathname}${clusterId}/mark_crops/`;
  return patchDataAuthorizedWithParams(pathname, props, body);
};

export const pauseWork = () => {
  const pathname = 'work/pause/';
  return postDataAuthorized(pathname);
}
export const resumeWork = () => {
  const pathname = 'work/resume/';
  return postDataAuthorized(pathname);
}
