import React, { Dispatch, FC, SetStateAction } from 'react';
import BulkActionsMenuContainer from '../../../components/BulkActionsMenuContainer/BulkActionsMenuContainer';

import { SelectFieldType } from '../../../components/Select/select.types';
import { cropsPlural } from '../../../utils';
import SelectMistakesBulkButton from './SelectMistakesBulkButton';

const BulkMistakesMenu: FC<{
  selectedCrops: Array<string>;
  setSelectedCrops: Dispatch<SetStateAction<Array<string>>>;
  mistakesList: Array<SelectFieldType>;
  onBulkSendMistakes: (el: any) => void;
}> = ({
  selectedCrops,
  setSelectedCrops,
  mistakesList,
  onBulkSendMistakes,
}) => {
  const cropsLabel = cropsPlural(selectedCrops.length);
  const title = `${selectedCrops.length} ${cropsLabel} selected:`;

  return (
    <BulkActionsMenuContainer
      title={title}
      onClose={() => setSelectedCrops([])}>
      <SelectMistakesBulkButton
        mistakesList={mistakesList}
        onBulkSendMistakes={onBulkSendMistakes}
      />
    </BulkActionsMenuContainer>
  );
};

export default BulkMistakesMenu;
