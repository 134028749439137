import React from 'react';
import { usePauseWorkContext } from 'contexts/PauseWorkContext';
import Icon from 'components/Icons';

const PauseMyWorkSwitch = (): JSX.Element => {
  const { paused, togglePauseWork } = usePauseWorkContext();

  const onSwitch = () => togglePauseWork();

  return (
    <button type="button" className="nav-link btn" onClick={onSwitch}>
      {paused ? (
        <>
          <Icon name="resume" className="nc-icon" style={{ marginRight: 15 }} />
          <p>Resume my work</p>
        </>
      ) : (
        <>
          <Icon name="pause" className="nc-icon" style={{ marginRight: 15 }} />
          <p>Pause my work</p>
        </>
      )}
    </button>
  );
};

export default PauseMyWorkSwitch;
