// TODO: Fixme
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useMemo, useRef, useState } from 'react';
import {
  Button,
  CloseButton,
  OverlayTrigger,
  Popover,
  PopoverTitle,
} from 'react-bootstrap';
import IconLinkButton from 'components/Buttons/IconLinkButton';
import { CheckIcon2 } from 'components/Icons';
import { Link } from 'react-router-dom';
import ConfirmModal from 'modals/ConfirmModal';
import { cropsPlural } from 'utils';
import { useHotkeys } from 'react-hotkeys-hook';
import classNames from 'classnames';
import { Cluster } from 'contexts/StationStateContext.types';
import {
  MAIN_CLUSTER_ID,
  useStationStateContext,
} from '../../../contexts/StationStateContext';
import useSettings from '../../../contexts/SettingsContext';
import { useUserContext } from '../../../contexts/Users';
import BulkActionsMenuContainer from '../../../components/BulkActionsMenuContainer/BulkActionsMenuContainer';

export type MarkOptions = {
  canMarkForMove: boolean;
  canMarkForRemove: boolean;
  canClearMarks: boolean;
} | null;

const MarkCropsButton: FC<{
  markOptions: MarkOptions;
  onMarkForRemove: () => void;
  onMarkForMove: () => void;
  onClearMarks: () => void;
}> = ({ markOptions, onMarkForRemove, onMarkForMove, onClearMarks }) => {
  const [markCropsPopoverVisible, setMarkCropsPopoverVisible] = useState(false);

  const handlePopoverToggle = () => {
    setMarkCropsPopoverVisible((prev) => !prev);
  };

  useHotkeys('f', () => handlePopoverToggle(), { enabled: !!markOptions });
  useHotkeys('r', () => onMarkForRemove(), {
    enabled: markCropsPopoverVisible && markOptions?.canMarkForRemove,
  });
  useHotkeys('m', () => onMarkForMove(), {
    enabled: markCropsPopoverVisible && markOptions?.canMarkForMove,
  });
  useHotkeys('delete', () => onClearMarks(), {
    enabled: markCropsPopoverVisible && markOptions?.canClearMarks,
  });

  if (!markOptions) return null;
  const markCropsOverlay = (
    <Popover id="markCrops">
      <PopoverTitle>
        <CloseButton onClick={handlePopoverToggle} />
        Mark&nbsp;
      </PopoverTitle>
      <Popover.Content>
        <div className="links-list">
          {markOptions.canMarkForRemove && (
            <IconLinkButton
              to="#"
              icon="flagRemove"
              onClick={onMarkForRemove}
              title="r">
              Mark for Removal
            </IconLinkButton>
          )}
          {markOptions.canMarkForMove && (
            <IconLinkButton
              to="#"
              icon="flagMove"
              onClick={onMarkForMove}
              title="m">
              Mark for Moving
            </IconLinkButton>
          )}
          {markOptions.canClearMarks && (
            <IconLinkButton
              to="#"
              icon="flagTrash"
              onClick={onClearMarks}
              title="Delete">
              Remove Selected Marks
            </IconLinkButton>
          )}
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      overlay={markCropsOverlay}
      show={markCropsPopoverVisible}
      onToggle={handlePopoverToggle}>
      <IconLinkButton icon="flag" className="menu-action" title="f">
        Mark
      </IconLinkButton>
    </OverlayTrigger>
  );
};

const MoveToClusterButton: FC<{
  clusters: Cluster[];
  selectedClusterId: string;
  nextDraftClusterNumber: number;
  onMoveToNewCluster: (name: string) => void;
  onMoveToCluster: (clusterId: string) => void;
}> = ({
  clusters,
  selectedClusterId,
  nextDraftClusterNumber,
  onMoveToNewCluster,
  onMoveToCluster,
}) => {
  const [clustersPopoverVisible, setClustersPopoverVisible] = useState(false);
  const [prevClusters, setPrevClusters] = useState<any>();
  const [newClusterName, setNewClusterName] = useState('');
  const [openedByKey, setOpenedByKey] = useState(false);
  const [chosenClusterId, setChosenClusterId] = useState('');
  const [addNewClusterPopoverVisible, setAddNewClusterPopoverVisible] =
    useState(false);

  const targetClusters = clusters.filter(({ id }) => id !== selectedClusterId);
  const title =
    targetClusters.length > 0
      ? 'Move to another cluster'
      : 'Move to new cluster';

  if (clusters !== prevClusters) {
    setPrevClusters(clusters);
    setNewClusterName(`Draft #${nextDraftClusterNumber}`);
  }

  const handlePopoverToggle = (keyboard = false) => {
    setOpenedByKey(keyboard);
    if (targetClusters.length && !clustersPopoverVisible) {
      setChosenClusterId(targetClusters[0].id);
    }
    setClustersPopoverVisible((prev) => {
      if (prev) setAddNewClusterPopoverVisible(false);
      return !prev;
    });
  };

  const handeAddNewCluster = () => {
    setClustersPopoverVisible(false);
    onMoveToNewCluster(newClusterName);
  };

  const newClusterNameRef = useRef<HTMLInputElement>(null);
  const focusNewClusterNameInput = () => {
    if (newClusterNameRef.current) {
      newClusterNameRef.current.focus();
      newClusterNameRef.current.select();
    }
  };

  useHotkeys(
    'n',
    () => {
      handlePopoverToggle(true);
      focusNewClusterNameInput();
    },
    { preventDefault: true },
  );
  useHotkeys(
    'ctrl+alt+n',
    () => {
      if (!clustersPopoverVisible) return;
      setChosenClusterId('new');
      setAddNewClusterPopoverVisible(true);
      focusNewClusterNameInput();
    },
    {
      preventDefault: true,
    },
  );

  const isNewClusterNameValid =
    !!newClusterName.trim().length &&
    !targetClusters.filter((el) => el.name === newClusterName).length;

  // Accept new cluster name
  useHotkeys(
    'Enter',
    () => !!newClusterNameRef.current && handeAddNewCluster(),
    {
      enabled: isNewClusterNameValid,
      enableOnFormTags: true,
      preventDefault: true,
    },
  );

  const chooseCluster = (down: boolean) => {
    if (!chosenClusterId) return;
    const index =
      chosenClusterId === 'new'
        ? targetClusters.length
        : targetClusters.findIndex(({ id }) => id === chosenClusterId);
    const newIndex =
      (index + (down ? 1 : -1) + targetClusters.length + 1) %
      (targetClusters.length + 1);
    const newChosenClusterId =
      newIndex === targetClusters.length ? 'new' : targetClusters[newIndex].id;
    setChosenClusterId(newChosenClusterId);
    if (newChosenClusterId === 'new') {
      setAddNewClusterPopoverVisible(true);
      focusNewClusterNameInput();
    } else {
      setAddNewClusterPopoverVisible(false);
    }
  };
  useHotkeys('up', () => chooseCluster(false), {
    enabled: openedByKey,
    enableOnFormTags: true,
    preventDefault: true,
  });
  useHotkeys('down', () => chooseCluster(true), {
    enabled: openedByKey,
    enableOnFormTags: true,
    preventDefault: true,
  });

  // Select existing cluster
  useHotkeys('Enter', () => onMoveToCluster(chosenClusterId), {
    enabled: openedByKey && chosenClusterId !== 'new',
    preventDefault: true,
  });

  const nameNewCluster = (
    <div>
      <label htmlFor="newClasterName" className="pb-2 mb-1 d-block">
        Name new cluster (optional)
      </label>
      <div className="text-nowrap">
        <input
          id="newClusterName"
          type="text"
          defaultValue={newClusterName}
          ref={newClusterNameRef}
          onChange={(e) => setNewClusterName(e.target.value)}
          title="Ctrl + Alt + n"
        />
        <Button
          className="p-1 d-inline-block"
          variant="outline-secondary"
          disabled={!isNewClusterNameValid}>
          <CheckIcon2 onClick={handeAddNewCluster} />
        </Button>
      </div>
    </div>
  );

  const addNewClusterOverlay = (
    <Popover id="addNewClusterPopover">
      <Popover.Content>{nameNewCluster}</Popover.Content>
    </Popover>
  );

  const moveToClusterOverlay = (
    <Popover id="moveToClusterPopover">
      <PopoverTitle>
        <CloseButton onClick={() => handlePopoverToggle(false)} />
        {title}&nbsp;
      </PopoverTitle>
      <Popover.Content>
        {!targetClusters.length ? (
          nameNewCluster
        ) : (
          <div className="links-list">
            {targetClusters.map(({ id, name }) => (
              <Link
                key={id}
                to="#"
                className={classNames({
                  chosen: id === chosenClusterId && openedByKey,
                })}
                onClick={() => onMoveToCluster(id)}>
                {name}
              </Link>
            ))}
            <OverlayTrigger
              trigger="click"
              placement="right"
              overlay={addNewClusterOverlay}
              show={addNewClusterPopoverVisible}
              onToggle={() => setAddNewClusterPopoverVisible((prev) => !prev)}>
              <Link
                to="#"
                key="addNewCluster"
                className={classNames({
                  chosen: chosenClusterId === 'new' && openedByKey,
                })}>
                Add new cluster
              </Link>
            </OverlayTrigger>
          </div>
        )}
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      overlay={moveToClusterOverlay}
      show={clustersPopoverVisible}
      onToggle={() => handlePopoverToggle(false)}>
      <IconLinkButton icon="newCluster" className="menu-action" title="n">
        {title}
      </IconLinkButton>
    </OverlayTrigger>
  );
};

export const CropActionsMenu = (): JSX.Element | null => {
  const {
    selectedClusterId,
    clusters,
    clusterCrops,
    performClusterAction,
    selectedClusterSelectedCrops,
    nextDraftClusterNumber,
    onMoveToNewCluster,
    onMoveToCluster,
    onUpdateSelections,
  } = useStationStateContext();

  const { isLabelerMode } = useSettings();
  const { isLabeler } = useUserContext();
  const [showRemoveCropsModal, setShowRemoveCropsModal] = useState(false);
  const isMainCluster = selectedClusterId === MAIN_CLUSTER_ID;
  const cropsCount = selectedClusterSelectedCrops.length;
  const onRemoveCrops = () => performClusterAction('removeSelectedCrops');
  const onDeselectAll = () => onUpdateSelections('unselectAll');

  const disableCropsMoving =
    isMainCluster &&
    !!selectedClusterSelectedCrops.length &&
    selectedClusterSelectedCrops.length ===
      clusterCrops[MAIN_CLUSTER_ID].length;

  const markOptions: MarkOptions = useMemo(
    () =>
      isLabeler || isLabelerMode
        ? null
        : {
            canMarkForRemove: !!selectedClusterSelectedCrops.find(
              ({ marked_for_action }) =>
                !marked_for_action || marked_for_action === 'to_move',
            ),
            canMarkForMove: !!selectedClusterSelectedCrops.find(
              ({ marked_for_action }) =>
                !marked_for_action || marked_for_action === 'to_remove',
            ),
            canClearMarks: !!selectedClusterSelectedCrops.find(
              ({ marked_for_action }) => marked_for_action,
            ),
          },
    [isLabeler, isLabelerMode, selectedClusterSelectedCrops],
  );

  const onMarkForMove = () => {
    performClusterAction(
      'markForMove',
      undefined,
      selectedClusterSelectedCrops.filter(
        ({ marked_for_action }) =>
          !marked_for_action || marked_for_action === 'to_remove',
      ),
    );
  };

  const onMarkForRemove = () => {
    performClusterAction(
      'markForRemove',
      undefined,
      selectedClusterSelectedCrops.filter(
        ({ marked_for_action }) =>
          !marked_for_action || marked_for_action === 'to_move',
      ),
    );
  };

  const onClearMarks = () => {
    return performClusterAction(
      'clearMarks',
      undefined,
      selectedClusterSelectedCrops.filter(
        ({ marked_for_action }) => marked_for_action,
      ),
    );
  };

  useHotkeys(
    'z',
    () => !disableCropsMoving && isMainCluster && setShowRemoveCropsModal(true),
    { enabled: !!cropsCount },
  );

  const cropsLabel = cropsPlural(cropsCount);

  if (!cropsCount) return null;
  const container = document.querySelector('.wrapper');
  if (!container) return null;
  const title = `${cropsCount} ${cropsLabel} selected:`;
  return (
    <BulkActionsMenuContainer title={title} onClose={onDeselectAll}>
      <MarkCropsButton
        markOptions={markOptions}
        onMarkForRemove={onMarkForRemove}
        onMarkForMove={onMarkForMove}
        onClearMarks={onClearMarks}
      />
      {!disableCropsMoving && (
        <>
          {isMainCluster && (
            <IconLinkButton
              icon="trashW"
              className="menu-action"
              onClick={() => setShowRemoveCropsModal(true)}
              title="z">
              Remove Crops
            </IconLinkButton>
          )}
          <MoveToClusterButton
            clusters={clusters}
            selectedClusterId={selectedClusterId}
            nextDraftClusterNumber={nextDraftClusterNumber}
            onMoveToNewCluster={onMoveToNewCluster}
            onMoveToCluster={onMoveToCluster}
          />
        </>
      )}
      <ConfirmModal
        header="Remove Crops"
        confirmLabel="Remove Crops"
        cancelLabel="Cancel"
        show={showRemoveCropsModal}
        onCancel={() => setShowRemoveCropsModal(false)}
        onConfirm={() => {
          setShowRemoveCropsModal(false);
          onRemoveCrops();
        }}
        size="lg">
        You&apos;re about to delete <b>{cropsCount}</b> {cropsLabel} from the
        cluster. Once deleted, this action cannot be reversed.
      </ConfirmModal>
    </BulkActionsMenuContainer>
  );
};

export default CropActionsMenu;
