import {EmptyFolderIcon} from 'components/Icons';
import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';

function Paused() {
    return (
        <Row className='d-flex flex-grow-1'>
            <Col className='d-flex flex-grow-1 flex-column justify-content-center align-content-center'>
                <Card className='d-flex flex-grow-1 new-ui'>
                    <Card.Header />
                    <Card.Body className="empty-cluster d-flex flex-grow-1">
                        <EmptyFolderIcon/>
                        <div className="main-text">Paused</div>
                        <div className="additional-text">
                            Press &quot;Resume my work to continue&quot;
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default Paused;
