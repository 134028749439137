export const START_PAGE = 1;

export default function getPageNumber(url?: string | null): number {
  if (!url) return 1;

  try {
    const pageParam = new URL(url).searchParams.get('page');
    const parsedPage = parseInt(pageParam ?? '', 10);
    return Number.isNaN(parsedPage) ? START_PAGE : parsedPage;
  } catch (error) {
    return START_PAGE;
  }
}
