import classNames from 'classnames';
import React, { FC } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import styles from './scss/station-buttons.module.scss';

const StationButton: FC<{
  label: string;
  clustersDone: number;
  hoursSpent: number;
  selected: boolean;
  onClick: () => void;
}> = ({ label, clustersDone, hoursSpent, selected, onClick }) => (
  <Col className="my-3" xs={4} lg={2}>
    <Button
      variant=""
      onClick={() => onClick()}
      className={classNames(
        styles.button,
        'p-3',
        'd-flex',
        'flex-grow-1',
        'flex-column',
        { [styles.button_selected]: selected },
      )}>
      <div className={styles.button__title}>{label}</div>
      <div className="mb-3 mt-2 d-flex flex-wrap align-items-baseline">
        <span className={`${styles.button__count} mr-2`}>{hoursSpent}</span>
        hours spent
      </div>
      <div className="d-flex flex-wrap align-items-baseline">
        <span className={`${styles.button__count} mr-2`}>{clustersDone}</span>
        clusters done
      </div>
    </Button>
  </Col>
);
StationButton.displayName = 'StationButton';

export interface StationInfo {
  label: string;
  value: string;
  clustersDone: number;
  hoursSpent: number;
}

const StationButtons: FC<{
  stationsInfo: StationInfo[];
  selectedStation: string | null;
  onSelectStation: (station: string) => void;
}> = ({ stationsInfo, selectedStation, onSelectStation }) => (
  <Row className="gap-1">
    {stationsInfo.map(({ label, value, clustersDone, hoursSpent }) => (
      <StationButton
        key={value}
        selected={value === selectedStation}
        label={label}
        clustersDone={clustersDone}
        hoursSpent={hoursSpent}
        onClick={() => onSelectStation(value)}
      />
    ))}
  </Row>
);
StationButtons.displayName = 'StationButtons';

export default StationButtons;
