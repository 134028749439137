import React, { FC, useEffect, useState } from 'react';
import Select from './Select';
import { getSelectInfo } from '../../datalayer/dataLayer';
import { SelectFieldType } from './select.types';

interface SelectAuditReportProps {
  selectType: 'clients' | 'models' | 'types' | 'containers';
  selected: SelectFieldType | null;
  setSelected: React.Dispatch<SelectFieldType>;
  className: string;
  isDisabled: boolean;
}

const SelectAuditReport: FC<SelectAuditReportProps> = ({
  selectType,
  selected,
  setSelected,
  className,
  isDisabled,
}) => {
  const [selectOptions, setSelectOptions] = useState<Array<SelectFieldType>>(
    [],
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const selectPlaceholder =
    selectType.charAt(0).toUpperCase() +
    selectType.slice(1, selectType.length - 1);

  useEffect(() => {
    getSelectInfo(selectType)
      .then((data) => setSelectOptions(data))
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  }, [selectType]);

  return error ? (
    <div className="d-flex align-items-center">
      <div
        className={`${className} error-text `}
        data-testid="select-audit-report__error">
        {selectPlaceholder} filter - error, <br />
        please refresh or try later
      </div>
    </div>
  ) : (
    <Select
      value={selected}
      onChange={setSelected}
      className={className}
      isDisabled={isDisabled}
      options={selectOptions}
      placeholder={selectPlaceholder}
      isLoading={isLoading}
      isSearchable
      isClearable
    />
  );
};

export default SelectAuditReport;
