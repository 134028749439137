import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { toPlural } from 'utils';
import classNames from 'classnames';
import styles from './scss/bar-chart.module.scss';
import { graphColor } from './graph.const';

const axisStyle = { stroke: graphColor.NEUTRAL_12, strokeDasharray: '2 2' };
const yTickStyle = {
  color: graphColor.NEUTRAL_65,
  fontSize: 12,
  fontFamily: 'Inter',
  fontWeight: '400',
};
const xTickStyle = {
  color: graphColor.NEUTRAL_65,
  fontSize: 12,
  fontFamily: `'Open Sans', sans-serif`,
  fontWeight: '400',
  lineHeight: 16,
  wordWrap: 'break-word',
};

export interface BarGraphProps {
  label?: string;
  nameField: string;
  valueFiled: string;
  data?: any[]; // Can this be typed?
  threshold?: number;
  color: string;
  belowThresholdColor?: string;
  nameFormatter?: (n: any) => string;
}

const BarGraph: FC<BarGraphProps> = ({
  label,
  nameField,
  valueFiled,
  data,
  threshold,
  color,
  belowThresholdColor,
  nameFormatter,
}) => {
  const [activeBarColor, setActiveBarColor] = useState<string>(
    graphColor.HIGH_ACTIVE_BAR,
  );
  const [overThershold, setOverThershold] = useState(false);
  const chartData = useMemo(() => {
    if (!data) return [];
    const hasThersholdColor = threshold !== undefined && belowThresholdColor;
    return data.map((item) => {
      const value = item[valueFiled];
      const res = {
        name: nameFormatter ? nameFormatter(item[nameField]) : item[nameField],
        value,
        fill: color,
      };
      if (hasThersholdColor && value < threshold) {
        res.fill = belowThresholdColor;
      }
      return res;
    });
  }, [
    belowThresholdColor,
    color,
    data,
    nameField,
    nameFormatter,
    threshold,
    valueFiled,
  ]);
  /* const graphMax = useMemo(
    () =>
      max ??
      chartData.reduce(
        (a, c) => Math.max(a, c.value),
        threshold ?? Number.MIN_SAFE_INTEGER,
      ),
    [chartData, max, threshold],
  ); */

  const customTooltip = useCallback(
    ({ ...props }: any) => {
      const { active, payload, label: name } = props;
      if (active && payload && payload.length) {
        const { value } = payload[0];
        if (overThershold) {
          return (
            <div
              className={classNames('py-1 px-2', styles.tooltip__container)}
              data-testid="tooltip__threshold">
              <span className="fw-500 mr-1">Base line:</span>
              <span>
                {threshold} {toPlural(value, 'cluster')} per hour
              </span>
            </div>
          );
        }
        return (
          <div
            className={classNames('py-1 px-2', styles.tooltip__container)}
            data-testid="tooltip">
            <span className="fw-500 mr-1">{name}:</span>
            <span>
              {value} {toPlural(value, 'cluster')} per hour
            </span>
          </div>
        );
      }

      return null;
    },
    [overThershold, threshold],
  );

  if (!data) return <div />;
  return (
    <>
      {label && <div className="fw-500 mb-2">{label}</div>}
      <div style={{ width: '100%', minHeight: '300px' }}>
        {/* fix for rechart error - Uncaught ResizeObserver loop completed with undelivered notifications. */}
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}>
            <CartesianGrid
              strokeDasharray={axisStyle.strokeDasharray}
              stroke={axisStyle.stroke}
              vertical={false}
            />
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={axisStyle}
              tick={xTickStyle}
            />
            <YAxis tickLine={false} axisLine={axisStyle} tick={yTickStyle} />
            <Tooltip
              content={customTooltip}
              cursor={false}
              isAnimationActive={false}
            />
            <Bar
              dataKey="value"
              onMouseOver={(d) => {
                if (!threshold) return;
                if (d.value >= threshold) {
                  setActiveBarColor(graphColor.HIGH_ACTIVE_BAR);
                } else {
                  setActiveBarColor(graphColor.LOW_ACTIVE_BAR);
                }
              }}
              activeBar={{ fill: activeBarColor }}
            />
            {threshold !== undefined && (
              <ReferenceLine
                y={threshold}
                stroke={graphColor.REFERENCE_LINE}
                strokeWidth={2}
                onMouseOver={() => setOverThershold(true)}
                onMouseLeave={() => setOverThershold(false)}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
BarGraph.displayName = 'BarGraph';

export default BarGraph;
