import React, { useState, useEffect, useCallback } from 'react';
import {
  Link,
  useParams,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { getAuditReportDetails } from '../../../api/auditReport';
import Table from '../../../components/Table/Table';
import ProductionReportDetailFilters from './ProductionReportDetailFilters';
import { PAGE_SIZE } from '../../../components/Paginators/ListPaginator';
import ProductionReportDetailsInfoCard from './ProductionReportDetailsInfoCard';

function ProductionReportDetails() {
  const [reportDetails, setReportDetails] = useState(null);
  const [prevId, setPrevId] = useState(null);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(null);
  const [count, setCount] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [parentCrop, setParentCrop] = useState(null);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [parameters, setParameters] = useState({});
  const [error, setError] = useState(false);
  const [currentState, setCurrentState] = useState({});
  const [prevSelected, setPrevSelected] = useState({});

  const { id } = useParams();
  const { state } = useLocation();

  useEffect(() => {
    if (state?.crop) {
      setCurrentState(state);
      const newParentCrop = { ...state.crop };
      newParentCrop.accuracy = state.crop.accuracy
        ? `${(100 * state.crop.accuracy).toFixed(2)}%`
        : '';
      newParentCrop.created_at = moment(state.crop.created_at).format(
        'YYYY-MM-DD HH:mm:ss',
      );
      newParentCrop.error_count = state.crop.error_count || '0';
      newParentCrop.errors_count_percent = state.crop.error_count
        ? `${(
            (100 * state.crop.crops_checked_count) /
            state.crop.error_count
          ).toFixed(2)}%`
        : '100%';
      newParentCrop.date_from = moment(state.crop.date_from).format(
        'YYYY-MM-DD',
      );
      newParentCrop.date_to = moment(state.crop.date_to).format('YYYY-MM-DD');

      const dateFrom = moment(newParentCrop.date_from);
      const dateTo = moment(newParentCrop.date_to);

      newParentCrop.days_duration = moment
        .duration(dateTo.diff(dateFrom))
        .asDays()
        .toFixed(0);
      setParentCrop(newParentCrop);
    }
  }, [state]);

  const roundScore = (score) => {
    const number = 10 ** 8;
    return Math.round(score * number) / number;
  };

  const setAuditReportDetails = useCallback(
    (params) => {
      getAuditReportDetails(id, { ...params, page_size: PAGE_SIZE }).then(
        async (response) => {
          const status = await response.status;
          if (status === 200) {
            const thisState = state || currentState;
            await response
              .json()
              .then((data) => {
                const rowNames = [];
                data.results.forEach((item) => {
                  rowNames.push({
                    Label: item.label,
                    'brandbank UUID': item.brandbank_uuid || 'unset',
                    'Sum Score': roundScore(item.score),
                    'Avg Score': roundScore(item.mean_score),
                    'Max Score': roundScore(item.max_score),
                    'Min Score': roundScore(item.min_score),
                    Check: (
                      <Link
                        to={{
                          pathname: `/check/${item.id}`,
                          state: {
                            label: item.label,
                            brandbank_uuid: item.brandbank_uuid,
                            pathnameBack: `/audit-report-details/${thisState.crop.id}`,
                            stateBack: { crop: thisState.crop, ...params },
                          },
                        }}
                      >
                        <button
                          className="btn btn-light d-flex align-items-center"
                          type="button"
                        >
                          <span>
                            <span className="text-nowrap">
                              {item.crops_checked_count} (auto{' '}
                              {item.crops_auto_checked_count})
                            </span>
                            <span className="text-nowrap">
                              {' '}
                              / {item.crops_count}
                            </span>
                          </span>
                          <i className="nc-icon nc-stre-right" />
                        </button>
                      </Link>
                    ),
                  });
                });
                setReportDetails(rowNames);
                setPrevId(id);
                setNext(data.next);
                setCount(data.count);
                setPrevious(data.previous);
                setError(false);
              })
              .catch(() => setError(true));
          } else {
            setError(true);
          }
          setIsLoadingSearch(false);
        },
      );
    },
    [currentState, id, state],
  );

  useEffect(() => {
    if (prevId !== id) {
      const newPage = state?.page || 1;
      setPage(newPage);
      const params = {};
      if (state?.search) params.search = state?.search;
      if (state?.ordering) params.ordering = state?.ordering;
      if (state?.brandbank_uuid) params.brandbank_uuid = state?.brandbank_uuid;
      setParameters(params);
      setPrevSelected(params);
      setAuditReportDetails({ page: newPage, ...params });
    }
  }, [
    id,
    prevId,
    setAuditReportDetails,
    state?.search,
    state?.ordering,
    state?.brandbank_uuid,
    state?.page,
  ]);

  const loadData = (params) => {
    setPage(params.page);
    setAuditReportDetails({ page: params.page, ...parameters });
  };

  const columnNames = [
    'Label',
    'brandbank UUID',
    'Sum Score',
    'Avg Score',
    'Max Score',
    'Min Score',
    'Check',
  ];

  const filterCallback = (selectedSorting, searchUUID, searchLabel) => {
    setIsLoadingSearch(true);
    const params = {};
    if (searchLabel) params.search = searchLabel;
    if (searchUUID) params.brandbank_uuid = searchUUID;
    if (selectedSorting) params.ordering = selectedSorting;
    setPage(1);
    setParameters(params);
    setAuditReportDetails({ page: 1, ...params });
  };

  return (
    <>
      <ProductionReportDetailsInfoCard parentCrop={parentCrop} id={id} />
      <ProductionReportDetailFilters
        filterCallback={filterCallback}
        isLoadingSearch={isLoadingSearch}
        prevSelected={prevSelected}
      />
      {error ? (
        <div className="alert alert-danger">Sorry, something went wrong</div>
      ) : (
        <Table
          page={page}
          next={next}
          count={count}
          previous={previous}
          rowNames={reportDetails}
          columnNames={columnNames}
          loadData={loadData}
        />
      )}
    </>
  );
}

export default ProductionReportDetails;
