import { postDiscardCluster } from 'api/clusters';
import IconLinkButton from 'components/Buttons/IconLinkButton';
import React, { FC, useState } from 'react';
import ConfirmationModal from '../../../modals/ConfirmationModal';
import { useStationStateContext } from '../../../contexts/StationStateContext';
import { checkResponse } from '../../../datalayer/dataLayer';

const IgnoreButton: FC<{ clusterId?: string; disabled: boolean }> = ({
  clusterId,
  disabled = true,
}) => {
  const { loading, setLoading, refreshData, setErrorMessage } =
    useStationStateContext();

  const [showModal, setShowModal] = useState(false);
  const handleAction = async () => {
    setLoading(true);
    await postDiscardCluster(clusterId)
      .then((response) => checkResponse(response, refreshData))
      .catch((error) => setErrorMessage(error.message));
  };
  return (
    <>
      <IconLinkButton
        icon="forbidden"
        className="mr-3"
        onClick={() => setShowModal(true)}
        disabled={!clusterId || disabled}
        data-testid="button-ignore">
        <span className="text-orange text-12">Ignore Cluster</span>
      </IconLinkButton>
      <ConfirmationModal
        showModal={showModal}
        disabled={loading}
        modalAction={(value) => {
          if (value) {
            handleAction();
          }
          setShowModal(false);
        }}>
        <h3>Confirm your action</h3>
        <p>Do you really want to do this?</p>
      </ConfirmationModal>
    </>
  );
};
IgnoreButton.displayName = 'IgnoreButton';

export default IgnoreButton;
