export const graphColor = {
  LOW_TEAM: '#F3C0C6',
  HIGH_TEAM: '#48B2EB',
  LOW_QUALITY: '#47AEB0',
  HIGH_QUALITY: '#F3C0C6',
  NEUTRAL_12: '#E5E6E6',
  NEUTRAL_65: '#727578',
  HIGH_ACTIVE_BAR: '#0B98E5',
  LOW_ACTIVE_BAR: '#D0021B',
  REFERENCE_LINE: '#F1BA24',
};
