import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  getIndividualPerformance,
  IndividualGraphKind,
  PerformanceStats,
} from 'datalayer/performanceReportsDL';
import { Station } from 'api/performanceReports';
import moment from 'moment';
import GraphsLoading from './GraphsLoading';
import GraphContainer, { PerfItem } from './GraphContainer';
import BarGraph from './BarGraph';
import { graphColor } from './graph.const';

// Efficiency performance
const EFF_PERF_ITEM_LABELS = {
  Total: 'Clusters done this month',
  Average: 'Average daily score per hour',
  Max: 'Highest daily score per hour',
  Min: 'Lowest daily score per hour',
};
// Quality performance
const Q_PERF_ITEM_LABELS = {
  Total: 'Rejected clusters this month',
  Average: 'Average daily rejects',
  Max: 'Highest daily score',
  Min: 'Lowest daily score',
};

const graphDateFormatter = (date: Date) => moment(date).format('MMM D');

interface IndividualGraphsProps {
  jobType: number | null;
  station: Station | null;
  date: Date | null;
}

const IndividualGraphs: FC<IndividualGraphsProps> = ({
  jobType,
  station,
  date,
}) => {
  const [loading, setLoading] = useState(false);
  const [effPerfItems, setEffPerfItems] = useState<PerfItem[]>([]);
  const [QPerfItems, setQPerfItems] = useState<PerfItem[]>([]);
  const [effGraphData, setEffGraphData] = useState<any[] | undefined>();
  const [qGraphData, setQGraphData] = useState<any[] | undefined>();
  const [effThreshold, setEffThreshold] = useState<number | undefined>();
  const [qThreshold, setQThreshold] = useState<number | undefined>();

  const empty = !(jobType && station && date);

  const parseStats = useCallback(
    (stats: Partial<Record<IndividualGraphKind, PerformanceStats>>) => {
      if (!stats.efficiency || !stats.quality) {
        setEffPerfItems([]);
        setQPerfItems([]);
        return;
      }
      setEffPerfItems([
        {
          label: EFF_PERF_ITEM_LABELS.Total,
          value: stats.efficiency.total,
          percentDelta: stats.efficiency.totalDelta,
        },
        {
          label: EFF_PERF_ITEM_LABELS.Average,
          value: stats.efficiency.average,
          percentDelta: stats.efficiency.averageDelta,
        },
        {
          label: EFF_PERF_ITEM_LABELS.Max,
          value: stats.efficiency.max,
          percentDelta: stats.efficiency.maxDelta,
        },
        {
          label: EFF_PERF_ITEM_LABELS.Min,
          value: stats.efficiency.min,
          percentDelta: stats.efficiency.minDelta,
        },
      ]);
      setQPerfItems([
        {
          label: Q_PERF_ITEM_LABELS.Total,
          value: stats.quality.total,
          percentDelta: stats.quality.totalDelta,
        },
        {
          label: Q_PERF_ITEM_LABELS.Average,
          value: stats.quality.average,
          percentDelta: stats.quality.averageDelta,
        },
        {
          label: Q_PERF_ITEM_LABELS.Max,
          value: stats.quality.max,
          percentDelta: stats.quality.maxDelta,
        },
        {
          label: Q_PERF_ITEM_LABELS.Min,
          value: stats.quality.min,
          percentDelta: stats.quality.minDelta,
        },
      ]);
    },
    [],
  );

  useEffect(() => {
    let ignoreLoad = false;
    if (!empty) {
      setLoading(true);
      setEffGraphData(undefined);
      setQGraphData(undefined);
      getIndividualPerformance(jobType, station, date).then(
        ({ graphData, stats, thresholds }) => {
          if (ignoreLoad) return;
          setLoading(false);
          parseStats(stats);
          setEffGraphData(graphData.efficiency);
          setQGraphData(graphData.quality);
          setEffThreshold(thresholds.efficiency);
          setQThreshold(thresholds.quality);
        },
      );
    }
    return () => {
      ignoreLoad = true;
    };
  }, [date, empty, jobType, parseStats, station]);

  return (
    <GraphsLoading empty={empty} loading={loading}>
      <GraphContainer
        label="My Efficiency"
        perfItems={effPerfItems}
        data-testid="container__efficiency">
        <BarGraph
          data={effGraphData}
          nameField="date"
          valueFiled="value"
          threshold={effThreshold}
          color={graphColor.HIGH_TEAM}
          belowThresholdColor={graphColor.LOW_TEAM}
          nameFormatter={graphDateFormatter}
        />
      </GraphContainer>
      <GraphContainer
        label="My Quality of Work"
        perfItems={QPerfItems}
        data-testid="container__quality">
        <BarGraph
          data={qGraphData}
          nameField="date"
          valueFiled="value"
          threshold={qThreshold}
          color={graphColor.HIGH_QUALITY}
          belowThresholdColor={graphColor.LOW_QUALITY}
          nameFormatter={graphDateFormatter}
        />
      </GraphContainer>
    </GraphsLoading>
  );
};
IndividualGraphs.displayName = 'IndividualGraphs';

export default IndividualGraphs;
