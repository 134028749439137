import React, { FC } from 'react';

export interface ReportCheckSearchButtonProps {
  isBBS?: boolean;
  value: string | null;
  label: string;
  onClick: (value: string | null) => void;
}

const ReportCheckSearchButton: FC<ReportCheckSearchButtonProps> = ({
  isBBS = false,
  value,
  label,
  onClick,
}) => {
  return (
    <p className="card-category my-1" data-testid={`search-button__${value}`}>
      <span className="d-inline-block mr-2" style={{ width: 50 }}>
        {value && (
          <a
            onClick={() => onClick(value)}
            className="d-inline-block page-link w-100 p-1 text-center"
            href="#"
            title={`${isBBS ? 'Brand Bank search' : 'Search'} for: ${value}`}>
            {isBBS ? 'BBS' : 'CS'}
          </a>
        )}
      </span>
      {label}: {value}
    </p>
  );
};

export default ReportCheckSearchButton;
