import { Alert, FormControl, InputGroup } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { useBatchLabelingContext } from '../../../contexts/BatchLabelingProcess';
import CardsList from '../../../components/CardList/CardsList';
import getPageNumber from '../../../helpers/getPageNumber';
import CustomSpinner from '../../../components/CustomSpinner/CustomSpinner';
import {
  EmptyFolderIcon,
  SearchIcon,
  SearchUserIcon,
} from '../../../components/Icons';
import IconLinkButton from '../../../components/Buttons/IconLinkButton';
import { useStationStateContext } from '../../../contexts/StationStateContext';

const FastLaneGPTManualSearch = (): React.ReactElement | null => {
  const {
    warning,
    infiniteLoadPredefinedClass,
    predefinedSet,
    clearSKU,
    count,
    next,
    isLoadingSKU,
  } = useBatchLabelingContext();
  const { selectedJobType, selectedLabel, setSelectedLabel, clusterData } =
    useStationStateContext();
  const [showMoreCharactersInfo, setShowMoreCharactersInfo] = useState(false);
  const [labelValue, setLabelValue] = useState('');

  const loadNextPage = () => {
    const pageNumber = getPageNumber(next);
    infiniteLoadPredefinedClass(labelValue, pageNumber);
  };

  useEffect(() => {
    if (!labelValue.trim()) {
      clearSKU();
      return;
    }

    if (labelValue.trim().length < 3) {
      setShowMoreCharactersInfo(true);
      clearSKU();
      return;
    }
    setShowMoreCharactersInfo(false);
    // search when user stops typing

    const delayDebounceFn = setTimeout(() => {
      if (labelValue.trim().length >= 3) {
        clearSKU();
        infiniteLoadPredefinedClass(labelValue.trim());
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
    // TODO: Fix me if you see me
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelValue]);

  const clearField = useCallback(() => {
    setLabelValue('');
    setSelectedLabel(null);
    clearSKU();
  }, [clearSKU, setSelectedLabel]);

  useEffect(() => {
    clearField();
  }, [clusterData, clearField]);

  if (!selectedJobType) return null;
  return (
    <>
      <div className="fw-500 mb-2">SKU Reference:</div>
      {warning && <Alert variant="warning">{warning}</Alert>}
      <InputGroup
        className="mb-3 input-group_background align-items-center"
        size="sm">
        <SearchIcon className="ml-2" />
        <FormControl
          aria-describedby="basic-addon2"
          autoFocus
          list="searchDropdownInputId"
          value={labelValue}
          placeholder="Search for new label"
          onChange={(e) => {
            clearSKU();
            setLabelValue(e.target.value);
          }}
          data-testid="input-search"
        />
        {labelValue && (
          <IconLinkButton
            icon="cross"
            onClick={clearField}
            data-testid="clear"
          />
        )}
      </InputGroup>
      {showMoreCharactersInfo && (
        <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center">
          <SearchUserIcon />
          <div className="main-text">
            Type at least 3 characters into search box
          </div>
        </div>
      )}
      {count === 0 && !showMoreCharactersInfo && !isLoadingSKU && (
        <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center">
          <EmptyFolderIcon />
          <div className="main-text">No results</div>
        </div>
      )}
      {isLoadingSKU && predefinedSet.length === 0 ? (
        <CustomSpinner />
      ) : (
        count > 0 &&
        labelValue.trim().length >= 3 && (
          <div
            className="d-flex flex-grow-1 mr-n3 pr-3 overflow-auto container_scrolled justify-content-center"
            id="scrollableDiv"
            data-testid="card-list-wrapper">
            <CardsList
              dataLength={count}
              next={loadNextPage}
              hasMore={!!next}
              scrollableTarget="scrollableDiv"
              items={predefinedSet}
              onCardClick={(item) => setSelectedLabel(item)}
              activeCardId={selectedLabel?.id}
            />
          </div>
        )
      )}
    </>
  );
};

export default FastLaneGPTManualSearch;
