import { Button, Col, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';

import {
  MAIN_CLUSTER_ID,
  useStationStateContext,
} from '../../../contexts/StationStateContext';
import StationControlPanel from '../../../components/StationControlPanel/StationControlPanel';
import { SubStateType } from '../../../enumsSubState';
import TabView, { TabViewItem } from '../../../components/TabView/TabView';
import { cropsPlural } from '../../../utils';
import CropsList from '../../../components/CropLists/CropsList';
import useSettings from '../../../contexts/SettingsContext';
import CropActionsMenu from '../Cleaning/CropActionsMenu';
import CropView from '../Cleaning/CropView';
import CropShelfView from '../Cleaning/CropSelfView';
import ConfirmModal from '../../../modals/ConfirmModal';
import FastLaneGPTWrapper from './FastLaneGPTWrapper';

const FastLaneStation = (): React.ReactElement => {
  const {
    setStationTypeContextState,
    selectedClusterId,
    clusters,
    performClusterAction,
    setSelectedClusterId,
    clusterCrops,
    clusterData,
    showRemoveClusterModal,
    setShowRemoveClusterModal,
  } = useStationStateContext();

  const { isAutoLoadOn } = useSettings();

  useEffect(() => {
    setStationTypeContextState(SubStateType.FAST_LANE);
    // TODO: Fix me if you see me
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StationControlPanel />

      <TabView
        activeKey={selectedClusterId}
        extraContent={
          clusters.length > 1 ? (
            <Button
              variant="outline-secondary"
              onClick={() => performClusterAction('acceptAllNewClusters')}
              data-testid="accept-new-clusters">
              Accept All New Clusters
            </Button>
          ) : null
        }
        onSelect={(key) => setSelectedClusterId(key || MAIN_CLUSTER_ID)}>
        {clusters.map(({ id, name }) => {
          const cropsCount = (clusterCrops[id] || []).length;
          return (
            <TabViewItem
              tabKey={id}
              label={`${name} (${cropsCount} ${cropsPlural(cropsCount)})`}
              key={id}>
              <div className="d-flex flex-grow-1">
                <Row className="d-flex flex-grow-1 pb-1 mb-2 w-100">
                  <Col md={8} className="d-flex flex-grow-1">
                    <CropsList
                      crops={clusterCrops[id] || []}
                      empty={!clusterData?.id}
                      paused={!isAutoLoadOn}
                      sortEnabled={id === MAIN_CLUSTER_ID}
                    />
                  </Col>
                  <Col md={4} className="d-flex flex-grow-1">
                    <FastLaneGPTWrapper />
                  </Col>
                </Row>
              </div>
            </TabViewItem>
          );
        })}
      </TabView>
      <CropActionsMenu />

      <CropView crops={clusterCrops[selectedClusterId]} />
      <CropShelfView />
      <ConfirmModal
        header="Remove Cluster"
        confirmLabel="Remove Cluster"
        cancelLabel="Cancel"
        show={showRemoveClusterModal}
        onCancel={() => setShowRemoveClusterModal(false)}
        onConfirm={() => {
          setShowRemoveClusterModal(false);
          performClusterAction('removeCluster');
        }}>
        Do you want to remove cluster?
      </ConfirmModal>
    </>
  );
};
FastLaneStation.displayName = 'FastLaneStation';

export default FastLaneStation;
