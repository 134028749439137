import React from 'react';
import { useUserContext } from '../../../contexts/Users';
import { StationStateContextProvider } from '../../../contexts/StationStateContext';
import FastLaneStation from './FastLane';

const FastLaneStationWrapper = (): React.ReactElement | null => {
  const { isExternal } = useUserContext();
  // TODO: Why is this needed?
  if (isExternal) return null;

  return (
    <StationStateContextProvider>
      <div className="new-ui station-container">
        <FastLaneStation />
      </div>
    </StationStateContextProvider>
  );
};

FastLaneStationWrapper.displayName = 'FastLaneStationWrapper';

export default FastLaneStationWrapper;
