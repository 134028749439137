import React from 'react';
import { Alert, Card, Col, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import JobTypeDropdown from '../Buttons/JobTypeDropdown';
import IconLinkButton from '../Buttons/IconLinkButton';
import RejectInfoComponent from '../CropLists/RejectInfoComponent';
import HistoryButton from '../../views/Clusters/Cleaning/HistoryButton';
import UserDetails from '../../views/Clusters/Cleaning/UserDetails';
import ClusterActionButtons from '../../views/Clusters/Cleaning/ClusterActionButtons';
import useSettings from '../../contexts/SettingsContext';
import { useUserContext } from '../../contexts/Users';
import { ErrorIcon } from '../Icons';
import SwitchButton from '../Buttons/SwitchButton';
import IgnoreButton from '../../views/Clusters/FastLane/IgnoreButton';
import { useStationStateContext } from '../../contexts/StationStateContext';
import { SubStateType } from '../../enumsSubState';
import FastLaneActionButtons from '../../views/Clusters/FastLane/FastLaneActionButtons';

// This is control panel for new UI

const StationControlPanel = (): React.ReactElement => {
  const {
    selectedJobType,
    jobTypes,
    onSelectJobType,
    onResumeWork,
    errorMessage,
    isRejected,
    clusterData,
    clusters,
    setShowRemoveClusterModal,
    lastRejectComment,
    setLastRejectComment,
    rejectListExpanded,
    setRejectListExpanded,
    contextStationType,
  } = useStationStateContext();

  const { isAutoLoadOn, setAutoLoad, isLabelerMode } = useSettings();
  const { isLabeler } = useUserContext();
  const isFastLane = contextStationType === SubStateType.FAST_LANE;
  const disableClusterActions = clusters.length > 1;
  return (
    <Card className="header-box">
      <Card.Header className="card-dark-header py-2">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <span className="text-black-75 py-1 mr-3 d-inline-block">
              Job Type{isLabeler && ':'}
            </span>
            {isLabeler ? (
              <span className="py-2 mr-3 d-inline-block">
                {selectedJobType?.name}
              </span>
            ) : (
              <div className="d-inline-block my-1">
                <JobTypeDropdown
                  predefinedClass
                  onClickJobType={onSelectJobType}
                  jobTypeList={jobTypes}
                  newUI
                />
              </div>
            )}
          </div>
          <div>
            <SwitchButton
              onTurnOn={onResumeWork}
              onTurnOff={() => setAutoLoad(false)}
              title="Autoload"
              checked={isAutoLoadOn}
            />
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        {errorMessage && (
          <Row className="mt-n1">
            <Col>
              <Alert variant="danger">{errorMessage}</Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} md={6}>
            <div className="h4">
              {clusterData ? `Cluster ID: ${clusterData?.id}` : 'Not selected'}
            </div>
          </Col>
          {/* TODO: Move to new component */}
          <Col xs={12} md={6}>
            {isRejected && clusterData && !isFastLane && (
              <Alert variant="danger" className="mt-n2 mb-0 py-2">
                <div className="d-flex">
                  <span className="text-truncate w-100">
                    <ErrorIcon />
                    <span className="fw-500">Rejected cluster: </span>
                    {lastRejectComment || (
                      <Spinner size="sm" animation="border" role="status" />
                    )}
                  </span>
                  <Link
                    className="pl-3 d-inline-block mt-1"
                    to="#"
                    onClick={() => setRejectListExpanded((prev: any) => !prev)}>
                    <FontAwesomeIcon
                      icon={rejectListExpanded ? faMinus : faPlus}
                    />
                  </Link>
                </div>
              </Alert>
            )}
          </Col>
        </Row>
        {isRejected && clusterData && !isFastLane && (
          <RejectInfoComponent
            key={clusterData.id}
            id={clusterData.id}
            setLastRejectComment={setLastRejectComment}
            expanded={rejectListExpanded}
          />
        )}
        <Row>
          <Col>
            <div className="d-flex align-items-center mt-3 pt-1">
              <HistoryButton clusterId={clusterData?.id} />
              {isFastLane && (
                <IgnoreButton
                  clusterId={clusterData?.id}
                  disabled={disableClusterActions}
                />
              )}
              <IconLinkButton
                icon="trash"
                className="mr-3"
                disabled={!clusterData?.id || disableClusterActions}
                onClick={() => setShowRemoveClusterModal(true)}>
                <span className="text-red text-12">Remove Cluster</span>
              </IconLinkButton>
              {!isFastLane && (
                <UserDetails
                  labelerId={clusterData?.labeler_id}
                  reviewerId={clusterData?.reviewer_id}
                  isLabeler={isLabeler || isLabelerMode}
                />
              )}
            </div>
          </Col>
          <Col
            md="auto"
            className="mt-2 pt-1 d-flex align-content-start justify-content-end flex-wrap gap-8">
            {isFastLane ? (
              <FastLaneActionButtons />
            ) : (
              <ClusterActionButtons
                key={clusterData?.id}
                isLabeler={isLabeler || isLabelerMode}
              />
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
StationControlPanel.displayName = 'StationControlPanel';

export default StationControlPanel;
