import React, { FC } from 'react';
import { Carousel, Col, Row } from 'react-bootstrap';
import defaultPhoto from 'assets/img/question-mark_2753.png';
import { PhotosTO } from '../../api/api.types';
import IconLinkButton from '../Buttons/IconLinkButton';

interface CardImageProps {
  photo: string;
  imageHash: string;
}
const CardImage: FC<CardImageProps> = ({ photo, imageHash }) => {
  const photoSrc = photo ? `${photo}?${imageHash}` : defaultPhoto;

  return (
    <div
      className="mx-auto d-flex flex-grow-1 align-self-center"
      style={{
        height: '88px',
        width: '88px',
      }}>
      <img
        alt=""
        src={photoSrc}
        className="img-fluid mx-auto h-100 d-block"
        style={{
          maxHeight: '100px',
          maxWidth: '100px',
        }}
      />
    </div>
  );
};

interface LabelCardProps {
  activeCard?: boolean;
  photos: PhotosTO[];
  title: string;
  text?: string;
}
const SingleCard: FC<LabelCardProps> = ({
  activeCard = false,
  photos = [],
  title,
  text,
}) => {
  const [imageHash, reloadImageHash] = React.useState(Date.now().toString());
  const photo = photos[0];
  const hasMorePhotos = photos.length > 1;
  const reloadImage = (event: React.MouseEvent<HTMLAnchorElement>) => {
    reloadImageHash(Date.now().toString());
    event.stopPropagation();
  };
  return (
    <Row
      className={`label-card mx-0 p-2 ${activeCard ? 'label-card_active' : ''}`}
      data-testid="label-card">
      <Col
        xs={3}
        className="d-flex justify-content-center align-items-center px-0">
        <div className="d-flex justify-content-center align-items-center label-card__image">
          <IconLinkButton
            icon="refresh"
            className="label-card__icon"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              reloadImage(event)
            }
          />
          {!hasMorePhotos ? (
            <CardImage photo={photo?.url} imageHash={imageHash} />
          ) : (
            <Carousel indicators={false} slide={false} data-testid="carousel">
              {photos &&
                photos.map((productPhoto) => (
                  <Carousel.Item key={productPhoto.id}>
                    <CardImage
                      photo={productPhoto?.url}
                      imageHash={imageHash}
                    />
                  </Carousel.Item>
                ))}
            </Carousel>
          )}
        </div>
      </Col>
      <Col xs={9} className="pr-0">
        <div className="fw-500 label-card__title">{title}</div>
        {text && <div className="mt-1 label-card__text">{text}</div>}
      </Col>
    </Row>
  );
};

export default SingleCard;
