import React, { useState, useEffect, FC } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import {
  getStatsFlagsAndMistakes,
  getStatsPerDay,
} from '../../../api/auditReport';
import { HttpStatus } from '../../../enums';
import InfoCardLine from './InfoCardLine';
import InfoCardMoreInfo from './InfoCardMoreInfo';
import CustomSpinner from '../../../components/CustomSpinner/CustomSpinner';

const ProductionReportDetailsInfoCard: FC<{
  id: string;
  parentCrop: any;
}> = ({ id, parentCrop }) => {
  const [isLoadingPerDay, setIsLoadingPerDay] = useState<boolean>(true);
  const [infoPerDay, setInfoPerDay] = useState<any>([]);
  const [errorPerDay, setErrorPerDay] = useState<boolean>(false);
  const [isLoadingMistakes, setIsLoadingMistakes] = useState<boolean>(true);
  const [infoMistakes, setInfoMistakes] = useState<any>([]);
  const [errorMistakes, setErrorMistakes] = useState<boolean>(false);

  useEffect(() => {
    getStatsFlagsAndMistakes(id).then(async (response: any) => {
      const status = await response.status;
      if (status === HttpStatus.SUCCESS) {
        await response
          .json()
          .then((data: any) => {
            const { mistake_types, flags } = data;
            const mistakeTypesArr = Object.entries(mistake_types).map((el) => ({
              label: el[0],
              value: el[1],
            }));
            setInfoMistakes({
              flags,
              mistake_types: mistakeTypesArr,
            });
            setErrorMistakes(false);
          })
          .catch(() => setErrorMistakes(true));
      } else {
        setErrorMistakes(true);
      }
      setIsLoadingMistakes(false);
    });
  }, [id]);

  const onShowStatsPerDay = () => {
    if (infoPerDay.length) return;
    const params = { ordering: 'date' };
    getStatsPerDay(id, params).then(async (response: any) => {
      const status = await response.status;
      if (status === HttpStatus.SUCCESS) {
        await response
          .json()
          .then((data: any) => {
            setInfoPerDay(data.results);
            setErrorPerDay(false);
          })
          .catch(() => setErrorPerDay(true));
      } else {
        setErrorPerDay(true);
      }
      setIsLoadingPerDay(false);
    });
  };

  return (
    <Card>
      <Card.Body className="p-3">
        <Row data-testid="report-details_info-card">
          <Col xs="12" md="6">
            <InfoCardLine label="Client" value={parentCrop?.client} />
            <InfoCardLine
              label="Containers"
              value={parentCrop?.containers.join(',  ')}
            />
            <InfoCardLine label="Model" value={parentCrop?.model} />
            <div className="d-flex">
              <InfoCardLine
                label="Th. Model"
                value={parentCrop?.threshold_model}
              />
              <span className="px-1">/</span>
              <InfoCardLine
                label="Th. Analysis"
                value={parentCrop?.threshold_analysis}
              />
            </div>
            <InfoCardLine label="Created" value={parentCrop?.created_at} />
            <InfoCardLine label="Accuracy" value={parentCrop?.accuracy} />
            <div className="d-flex">
              <InfoCardLine
                label="Errors count"
                value={`${parentCrop?.crops_checked_count} / ${parentCrop?.error_count}`}
              />
              <span className="pl-1">
                ( {parentCrop?.errors_count_percent} )
              </span>
            </div>
          </Col>
          <Col xs="12" md="6">
            <Row>
              <Col xs="12" md="5">
                <div className="d-flex pt-2 mt-1">
                  <InfoCardLine
                    label="Images"
                    value={parentCrop?.images_count}
                  />
                  <span className="px-1">/</span>
                  <InfoCardLine label="Crops" value={parentCrop?.crops_count} />
                </div>
                <InfoCardLine label="Days" value={parentCrop?.days_duration} />
                <ul>
                  <li>
                    <InfoCardLine
                      label="From"
                      value={
                        parentCrop?.date_from &&
                        moment(parentCrop?.date_from).format('YYYY-MM-DD')
                      }
                    />
                  </li>
                  <li>
                    <InfoCardLine
                      label="To"
                      value={
                        parentCrop?.date_to &&
                        moment(parentCrop?.date_to).format('YYYY-MM-DD')
                      }
                    />
                  </li>
                </ul>
              </Col>
              <Col xs="12" md="7">
                <InfoCardMoreInfo
                  variant="day"
                  data={infoPerDay}
                  isLoading={isLoadingPerDay}
                  isError={errorPerDay}
                  onClick={onShowStatsPerDay}
                />
              </Col>
            </Row>
            {isLoadingMistakes ? (
              <CustomSpinner />
            ) : (
              <Row>
                {errorMistakes ? (
                  <>
                    Flags:
                    <span className="error-text text-nowrap ml-1">
                      Sorry, something went wrong
                    </span>
                  </>
                ) : (
                  <>
                    <Col xs="12" md="5">
                      <div className="pt-2 mt-1">
                        <span className="text-muted">Flags:</span>
                        <ul>
                          <li>
                            <InfoCardLine
                              label="Wrong Both"
                              value={infoMistakes?.flags.WRONG_BOTH}
                            />
                          </li>
                          <li>
                            <InfoCardLine
                              label="Wrong Prediction"
                              value={infoMistakes?.flags.WRONG_PREDICTION}
                            />
                          </li>
                          <li>
                            <InfoCardLine
                              label="Wrong Tagged"
                              value={infoMistakes?.flags.WRONG_TAGGED}
                            />
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col xs="12" md="7">
                      <InfoCardMoreInfo
                        variant="type"
                        data={infoMistakes?.mistake_types}
                      />
                    </Col>
                  </>
                )}
              </Row>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProductionReportDetailsInfoCard;
