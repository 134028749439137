import React, { FC } from 'react';
import { SpinnerIcon } from '../Icons';

interface CustomSpinnerProps {
  backdrop?: boolean;
}
const CustomSpinner: FC<CustomSpinnerProps> = ({
  backdrop = false,
}): React.ReactElement => {
  const spinner = (
    <div
      className="d-flex justify-content-center custom-svg-spinner"
      data-testid="custom-spinner">
      <SpinnerIcon />
    </div>
  );

  return backdrop ? <div className="backdrop">{spinner}</div> : spinner;
};

export default CustomSpinner;
