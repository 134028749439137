import classNames from 'classnames';
import React, { FC } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import styles from './scss/graph-container.module.scss';
import Icon from '../../../components/Icons';

export interface PerfItem {
  label: string;
  value: number;
  percentDelta: number;
}

interface GraphContainerProps {
  label: string;
  perfItems: PerfItem[];
}

const GraphContainer: FC<GraphContainerProps> = ({
  label,
  perfItems,
  children,
}) => {
  return (
    <Card className="d-flex w-100">
      <Card.Body className="d-flex flex-column">
        <Row>
          <Col className="{styles.graph__title} mb-4">{label}</Col>
        </Row>
        <Row className="d-flex mb-4">
          {perfItems.map(({ label: piLabel, value, percentDelta }) => (
            <Col key={piLabel}>
              <div className="d-flex align-items-top mb-2">
                <span className={styles.graph__count}>{value}</span>
                <span
                  className={classNames(styles.graph__percent, {
                    [styles.negative]: percentDelta < 0,
                    'text-red': percentDelta < 0,
                    'text-green': percentDelta >= 0,
                  })}>
                  <Icon name="arrow" />
                  {percentDelta}%
                </span>
              </div>
              <div className={styles.graph__label}>{piLabel}</div>
            </Col>
          ))}
        </Row>
        {children}
      </Card.Body>
    </Card>
  );
};
GraphContainer.displayName = 'GraphContainer';

export default GraphContainer;
