import React, { FC, useMemo, useState } from 'react';
import Switch from 'components/Buttons/Switch';
import { Col, Row } from 'react-bootstrap';
import Chance from 'chance';
import DatePicker from 'react-datepicker';
import { Station } from 'api/performanceReports';
import moment from 'moment';
import {
  StationStateContextProvider,
  useStationStateContext,
} from '../../../contexts/StationStateContext';

import { useUserContext } from '../../../contexts/Users';
import useSettings from '../../../contexts/SettingsContext';
import SelectWithIcon from '../../../components/Select/SelectWithIcon';
import 'react-datepicker/dist/react-datepicker.css';
import './scss/datepicker.css'; // custom react-datepicker styles
import { CalendarIcon } from '../../../components/Icons';
import StationButtons from './StationButtons';
import TeamGraphs from './TeamGraphs';
import IndividualGraphs from './IndividualGraphs';

// TODO: Type me
const RenderMonthContent: any = (
  month: any,
  shortMonth: any,
  longMonth: any,
  day: any,
) => {
  const fullYear = new Date(day).getFullYear();
  const tooltipText = `${longMonth} ${fullYear}`;
  return <span title={tooltipText}>{shortMonth}</span>;
};

const PerformanceReport: FC = () => {
  const { jobTypes, onSelectLabelerMode } = useStationStateContext();
  const { isAdmin, isQC } = useUserContext();
  const { isLabelerMode } = useSettings();
  const [selectedJobType, setSelectedJobType] = useState<number | null>(null);
  const [selectedStation, setSelectedStation] = useState<Station | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<Date | null>(null);

  const stations = useMemo(
    () =>
      [
        { label: 'Pre-filtering', value: 'PRE-FILTERING' },
        { label: 'Cleaning', value: 'CLEANING' },
        { label: 'Cleaning', value: 'CLEANING-QC' },
        { label: 'Identification', value: 'IDENTIFICATION' },
        { label: 'Identification', value: 'IDENTIFICATION-QC' },
        { label: 'Fast Lane', value: 'FAST-LANE' },
      ].filter(({ value }) => {
        if (isLabelerMode)
          return !['CLEANING-QC', 'IDENTIFICATION-QC', 'FAST-LANE'].includes(
            value,
          );
        if (isQC) {
          return !['CLEANING', 'IDENTIFICATION'].includes(value);
        }
        return true;
      }),
    [isLabelerMode, isQC],
  );

  if (selectedStation && !stations.find((s) => s.value === selectedStation)) {
    // setSelectedStation(null); Doesn't work, selecting similar station instead
    setSelectedStation(
      (stations.find(
        (s) => s.value.substring(0, 5) === selectedStation.substring(0, 5),
      )?.value as Station) || null,
    );
  }

  const jobTypesList = useMemo(
    () =>
      jobTypes.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    [jobTypes],
  );

  const sRnd = new Chance('station stats');
  // TODO: Load me
  const stationsInfo = stations.map(({ label, value }) => ({
    label,
    value,
    clustersDone: sRnd.integer({ min: 0, max: 1000000 }),
    hoursSpent: sRnd.integer({ min: 0, max: 1000000 }),
  }));

  const onSelectJobType = ({ value }: any) =>
    setSelectedJobType(value as number);

  const onSelectStation = ({ value }: any) =>
    setSelectedStation(value as Station);

  const onSelectMonth = (date: Date) => setSelectedMonth(date);

  return (
    <>
      <Row className="gap-2 mb-3">
        <Col md={3}>
          <SelectWithIcon
            icon="briefcase"
            placeholder="Job Type"
            id="jobTypeSelect"
            onChange={onSelectJobType}
            options={jobTypesList}
          />
        </Col>
        {!isAdmin && (
          <Col md={3}>
            <SelectWithIcon
              icon="stairs"
              placeholder="Station"
              id="stationSelect"
              onChange={onSelectStation}
              options={stations}
              value={stations.find((s) => s.value === selectedStation)}
            />
          </Col>
        )}
        <Col md={3}>
          <DatePicker
            showIcon
            icon={<CalendarIcon />}
            renderMonthContent={RenderMonthContent}
            showMonthYearPicker
            onChange={onSelectMonth}
            dateFormat="MM/yyyy"
            placeholderText="Time frame"
            value={selectedMonth ? moment(selectedMonth).format('MM/yyyy') : ''}
          />
        </Col>
        <Row className="gap-2" />
      </Row>
      {isAdmin && (
        <StationButtons
          stationsInfo={stationsInfo}
          selectedStation={selectedStation}
          onSelectStation={onSelectStation}
        />
      )}
      <Row className="d-flex flex-grow-1 overflow-auto px-3 container_scrolled">
        {isAdmin ? (
          <TeamGraphs />
        ) : (
          <IndividualGraphs
            jobType={selectedJobType}
            station={selectedStation}
            date={selectedMonth}
          />
        )}
      </Row>
      {/* TODO: Make me a component */}
      {isQC && (
        <div className="d-flex mb-2 justify-content-end labeler-switch">
          <Switch
            name="1"
            checked={isLabelerMode}
            setChecked={onSelectLabelerMode}
            label="Labeler View Mode"
          />
        </div>
      )}
    </>
  );
};
PerformanceReport.displayName = 'PerformanceReport';

const PerformanceReportWrapper = (): JSX.Element => (
  <StationStateContextProvider>
    <div className="new-ui station-container">
      <PerformanceReport />
    </div>
  </StationStateContextProvider>
);
PerformanceReportWrapper.displayName = 'PerformanceReportWrapper';

export default PerformanceReportWrapper;
