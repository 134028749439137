import React from 'react';
import PropTypes from 'prop-types';
import UserContextProvider from './Users';
import FeatureStoreContextProvider from './FeatureStore';
import CropReviewProcessProvider from './CropReviewProcess';
import LayoutContextProvider from './LayoutContext';
import BatchLabelingProcess from './BatchLabelingProcess';
import ImageFinderProcess from './ImageFinderProcess';
import { SettingsProvider } from './SettingsContext';
import PauseWorkContextProvider from './PauseWorkContext';

function ContextWrapper({ children }) {
  return (
    <LayoutContextProvider>
      <UserContextProvider>
        <FeatureStoreContextProvider>
          <CropReviewProcessProvider>
            <BatchLabelingProcess>
              <ImageFinderProcess>
                <PauseWorkContextProvider>
                  <SettingsProvider>{children}</SettingsProvider>
                </PauseWorkContextProvider>
              </ImageFinderProcess>
            </BatchLabelingProcess>
          </CropReviewProcessProvider>
        </FeatureStoreContextProvider>
      </UserContextProvider>
    </LayoutContextProvider>
  );
}

ContextWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default ContextWrapper;
