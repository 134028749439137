import React, { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHotkeys } from 'react-hotkeys-hook';

// New UI modal
const ConfirmModal: FC<{
  show: boolean;
  header?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: () => void;
  onCancel: () => void;
  disabled?: boolean;
  size?: 'sm' | 'lg' | 'xl';
}> = ({
  show,
  header,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  disabled,
  children,
  size,
}) => {
  useHotkeys('enter', () => !disabled && onConfirm && onConfirm(), {
    enabled: show,
  });
  useHotkeys('ctrl+enter', () => !disabled && onConfirm && onConfirm(), {
    enabled: show,
    enableOnContentEditable: true,
    enableOnFormTags: true,
  });

  useHotkeys('escape', () => onCancel(), { enabled: show });
  return (
    <Modal
      className="modal-primary modal-new-ui"
      show={show}
      centered
      onHide={() => onCancel()}
      size={size}>
      {header && <Modal.Header>{header}</Modal.Header>}
      <Modal.Body>{children}</Modal.Body>
      <div className="modal-footer">
        <Button
          className="btn"
          type="button"
          variant="link"
          onClick={() => onCancel()}>
          {cancelLabel ?? 'Close'}
        </Button>
        {onConfirm && (
          <Button
            disabled={disabled}
            className="btn btn-fill"
            type="button"
            variant="link"
            onClick={() => onConfirm()}>
            {confirmLabel ?? 'Accept'}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmModal;
