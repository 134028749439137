import React, { FC } from 'react';

const InfoCardLine: FC<{
  label: string;
  value: string;
}> = ({ label, value }) => {
  return (
    <div data-testid={`info-card-line_${label}`}>
      <span className="text-muted pr-1">{label}:</span>
      {value}
    </div>
  );
};

export default InfoCardLine;
